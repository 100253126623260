.about-page {
  padding-top: 90px;
}

/* Board of Directors Section */
.board-section {
  padding: 4rem 0;
  background: #f8f9fa;
}

.container {
  max-width: 900px;
  margin: 0 auto;
  padding: 0 1.5rem;
}

.board-section h2 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 3rem;
  color: var(--text-dark);
}

.directors-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 3rem;
  max-width: 1200px;
  margin: 0 auto;
}

.director-card {
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 4rem;
  padding: 3rem;
  background: white;
  border-radius: 15px;
  box-shadow: var(--shadow-md);
  align-items: start;
}

.director-card:hover {
  transform: translateY(-10px);
  box-shadow: var(--shadow-lg);
}

.director-image {
  width: 100%;
  height: 380px;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
  box-shadow: var(--shadow-sm);
}

.director-card:hover .director-image {
  transform: scale(1.05);
}

.director-info {
  padding: 0;
  height: 380px;
  overflow-y: auto;
}

.director-info h3 {
  font-size: 2rem;
  color: var(--primary-blue);
  margin-bottom: 0.5rem;
}

.director-info h4 {
  font-size: 1.2rem;
  color: var(--text-dark);
  margin-bottom: 1.2rem;
}

.director-title {
  font-size: 1.2rem;
  color: var(--primary-green);
  margin-bottom: 2rem;
}

.director-desc {
  margin-bottom: 1.5rem;
  line-height: 1.8;
  color: var(--text-light);
  font-size: 1.1rem;
}

/* Management Team Section */
.team-section {
  padding: 4rem 0;
  background: white;
}

.team-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
}

.team-group h3 {
  text-align: center;
  color: var(--primary-blue);
  margin-bottom: 2rem;
  font-size: 1.4rem;
}

.team-member {
  background: #f8f9fa;
  padding: 1.2rem;
  border-radius: 20px;
  text-align: center;
  transition: all 0.3s ease;
}

.team-member:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-md);
}

.member-avatar {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.team-member h4 {
  font-size: 1.2rem;
  color: var(--text-dark);
  margin-bottom: 0.5rem;
}

.member-title {
  color: var(--primary-green);
  font-weight: 600;
  margin-bottom: 1rem;
}

.member-desc {
  color: var(--text-light);
  line-height: 1.6;
  font-size: 0.95rem;
}

/* Logo Meaning Section */
.logo-section {
  padding: 4rem 0;
  background: #f8f9fa;
  overflow: hidden;
  position: relative;
}

.logo-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  align-items: center;
  margin-bottom: 6rem;
  position: relative;
  z-index: 1;
}

.meaning-logo {
  width: 100%;
  max-width: 350px;
  border-radius: 20px;
  box-shadow: var(--shadow-md);
}

.logo-info h2 {
  font-size: 1.8rem;
  color: var(--text-dark);
  margin-bottom: 2rem;
}

.logo-info p {
  color: var(--text-light);
  line-height: 1.8;
  margin-bottom: 1rem;
}

/* Brandmark Section */
.brandmark {
  text-align: center;
  margin-top: 6rem;
  padding: 3rem;
  background: linear-gradient(135deg, 
    rgba(102,189,64,0.05),
    rgba(56,119,186,0.05)
  );
  border-radius: 30px;
  position: relative;
  z-index: 0;
}

.brandmark h3 {
  font-size: 2rem;
  color: var(--text-dark);
  margin-bottom: 3rem;
}

.brandmark-main {
  width: 100%;
  max-width: 350px;
  margin: 0 auto 3rem;
  border-radius: 20px;
  box-shadow: var(--shadow-md);
  display: block;
  position: relative;
  z-index: 0;
}

.brandmark p {
  max-width: 600px;
  margin: 0 auto 3rem;
  color: var(--text-light);
  line-height: 1.8;
}

.brandmark-details {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  z-index: 0;
}

.brandmark-detail {
  width: 100%;
  max-width: 350px;
  border-radius: 20px;
  box-shadow: var(--shadow-md);
}

/* 响应式调整 */
@media (max-width: 768px) {
  .brandmark {
    padding: 2rem 1rem;
    margin-top: 4rem;
  }

  .brandmark h3 {
    font-size: 1.8rem;
  }

  .brandmark-details {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .brandmark-detail {
    margin: 0 auto;
    max-width: 280px;
  }

  .logo-content {
    margin-bottom: 4rem;
  }

  .logo-info {
    padding: 0 1rem;
    margin-bottom: 2rem;
  }
}

/* Vision & Mission Section 样式修改 */
.vision-mission {
  padding: 4rem 0;
  background: #f8f9fa;
}

.vision-mission .container {
  display: grid;
  grid-template-columns: 1fr 1fr;  /* 两列布局 */
  gap: 4rem;
  align-items: center;
}

.vision-mission-image {
  width: 100%;
  max-width: 350px;
  border-radius: 20px;
  box-shadow: var(--shadow-md);
  transition: all 0.3s ease;
}

.vision-mission-image:hover {
  transform: translateY(-10px);
  box-shadow: var(--shadow-lg);
}

.vision-mission-content {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.vision, .mission {
  padding: 2rem;
  background: white;
  border-radius: 20px;
  box-shadow: var(--shadow-md);
  transition: all 0.3s ease;
}

.vision:hover, .mission:hover {
  transform: translateX(10px);
}

.vision h2, .mission h2 {
  font-size: 1.6rem;
  color: var(--text-dark);
  margin-bottom: 1rem;
}

.vision p, .mission p {
  color: var(--text-light);
  line-height: 1.8;
  font-size: 0.95rem;
}

/* 响应式调整 */
@media (max-width: 1024px) {
  .vision-mission .container {
    grid-template-columns: 1fr;
    gap: 3rem;
  }

  .vision-mission-image {
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  .vision-mission {
    padding: 4rem 0;
  }

  .vision h2, .mission h2 {
    font-size: 1.8rem;
  }

  .vision p, .mission p {
    font-size: 1rem;
  }
}

/* Core Values Section */
.core-values {
  padding: 4rem 0;
  background: #f8f9fa;
}

.core-values-image {
  width: 100%;
  max-width: 350px;
  margin: 0 auto 4rem;
  display: block;
  border-radius: 20px;
  box-shadow: var(--shadow-md);
}

.core-values h2 {
  text-align: center;
  font-size: 1.8rem;
  color: var(--text-dark);
  margin-bottom: 1.5rem;
}

.values-intro {
  text-align: center;
  color: var(--text-light);
  line-height: 1.8;
  max-width: 800px;
  margin: 0 auto 4rem;
}

.values-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

.value-item {
  background: white;
  padding: 1.5rem;
  border-radius: 20px;
  box-shadow: var(--shadow-md);
  transition: all 0.3s ease;
}

.value-item:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-lg);
}

.value-item h3 {
  font-size: 1.2rem;
  color: var(--primary-blue);
  margin-bottom: 1.5rem;
}

.value-item p {
  color: var(--text-light);
  line-height: 1.8;
}

/* 响应式设计 */
@media (max-width: 1024px) {
  .directors-grid,
  .values-grid {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .team-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .logo-content {
    grid-template-columns: 1fr;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .team-grid {
    grid-template-columns: 1fr;
  }

  .director-image {
    height: 250px;
  }

  .brandmark-details {
    grid-template-columns: 1fr;
  }

  .vision h2, .mission h2, .core-values h2 {
    font-size: 1.8rem;
  }

  .brandmark {
    padding: 3rem 1.5rem;
    margin-top: 4rem;
  }

  .brandmark h3 {
    font-size: 2rem;
    margin-bottom: 3rem;
  }

  .brandmark-details {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .brandmark p {
    font-size: 1rem;
    margin-bottom: 3rem;
    padding: 0 1rem;
  }

  .board-section h2 {
    font-size: 2.2rem;
  }

  .director-info {
    padding: 2rem;
  }

  .director-info h3 {
    font-size: 1.4rem;
  }

  .director-info h4 {
    font-size: 1.2rem;
  }

  .meaning-logo,
  .brandmark-main,
  .brandmark-detail,
  .vision-mission-image,
  .core-values-image {
    max-width: 100%;
  }

  .container {
    padding: 0 1rem;
  }

  .member-avatar {
    font-size: 2rem;
  }
}

/* 添加新的图片部分样式 */
.about-image-section {
  padding: 4rem 0;
  background: #f8f9fa;
}

.about-image-section .section-title {
  text-align: center;
  font-size: 2.2rem;
  color: var(--text-dark);
  margin-bottom: 2rem;
}

.about-full-image {
  width: 100%;
  max-width: 1200px;
  height: auto;
  margin: 0 auto;
  display: block;
}

@media (max-width: 768px) {
  .about-image-section .section-title {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
  }
  
  .about-full-image {
    width: 100%;
  }
}

/* Company Group Section */
.company-group-section {
  padding: 4rem 0;
  background: #f8f9fa;
}

.company-group-section h2 {
  font-size: 2.5rem;
  color: var(--text-dark);
  margin-bottom: 2rem;
  text-align: center;
}

.company-list {
  max-width: 900px;
  margin: 0 auto;
}

.company-item {
  background: white;
  padding: 1.5rem 2rem;
  border-radius: 10px;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.company-item h3 {
  font-size: 1.2rem;
  color: var(--text-dark);
  margin: 0;
}

.company-reg {
  color: var(--text-light);
  font-weight: normal;
}

/* Company History Section */
.company-history-section {
  padding: 6rem 0 4rem;
  background: white;
}

.history-paragraph {
  color: var(--text-dark);
  line-height: 1.8;
  margin-bottom: 2rem;
  font-size: 1.1rem;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.history-paragraph:last-child {
  margin-bottom: 0;
}

/* 响应式调整 */
@media (max-width: 768px) {
  .company-history-section {
    padding: 5rem 1rem 3rem;
  }
  
  .history-paragraph {
    font-size: 1rem;
  }
}

.blue-text {
  color: var(--primary-blue);
  font-weight: 600;
}

.single-director {
  grid-template-columns: 1fr !important;
  max-width: 1200px;
  margin: 0 auto;
}

.single-director .director-card {
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 4rem;
  padding: 3rem;
  background: white;
  border-radius: 15px;
  box-shadow: var(--shadow-md);
  align-items: start;
}

.single-director .director-image {
  width: 300px;
  height: 380px;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
  box-shadow: var(--shadow-sm);
}

.single-director .director-info {
  padding: 0;
  height: 380px;
  overflow-y: auto;
}

.single-director .director-info h3 {
  font-size: 2rem;
  color: var(--primary-blue);
  margin-bottom: 0.5rem;
}

.single-director .director-title {
  font-size: 1.2rem;
  color: var(--primary-green);
  margin-bottom: 2rem;
}

.single-director .director-desc {
  margin-bottom: 1.5rem;
  line-height: 1.8;
  color: var(--text-light);
  font-size: 1.1rem;
}

/* 响应式调整 */
@media (max-width: 1024px) {
  .single-director {
    max-width: 90%;
  }
  
  .single-director .director-card {
    grid-template-columns: 250px 1fr;
    gap: 3rem;
  }

  .single-director .director-image {
    width: 250px;
    height: 350px;
  }

  .single-director .director-info {
    height: 350px;
  }
}

@media (max-width: 768px) {
  .single-director .director-card {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .single-director .director-image {
    width: 240px;
    height: 300px;
    margin: 0 auto 2rem;
  }

  .single-director .director-info {
    height: auto;
    max-height: none;
    overflow-y: visible;
  }
  
  .single-director .director-info h3,
  .single-director .director-info .director-title {
    text-align: center;
  }

  .single-director .director-info h3 {
    font-size: 1.8rem;
  }
}

/* 移动端适配 */
@media (max-width: 768px) {
  .about-page {
    padding-top: 70px;
  }

  /* 董事会部分适配 */
  .director-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    padding: 1.5rem;
    position: relative;
  }

  .director-image {
    width: 100%;
    max-width: 280px;
    height: 350px;
    object-fit: cover;
    border-radius: 10px;
    margin: 0 auto;
    transition: all 0.3s ease;
    order: -1; /* 确保图片在最上面 */
  }

  .director-info {
    width: 100%;
    height: auto;
    max-height: 200px;
    overflow: hidden;
    transition: max-height 0.3s ease;
    position: relative;
    padding: 0 0.5rem;
  }

  .director-info.expanded {
    max-height: none;
  }

  .director-info h3 {
    font-size: 1.6rem;
    text-align: center;
    margin-bottom: 0.5rem;
  }

  .director-title {
    font-size: 1.1rem;
    text-align: center;
    margin-bottom: 1rem;
    color: var(--primary-green);
  }

  .director-desc {
    font-size: 1rem;
    text-align: left;
    margin-bottom: 1rem;
    line-height: 1.6;
  }

  /* 渐变遮罩 */
  .director-info::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background: linear-gradient(transparent, white);
    pointer-events: none;
    opacity: 1;
    transition: opacity 0.3s ease;
  }

  .director-info.expanded::after {
    opacity: 0;
  }

  /* 查看更多按钮 */
  .read-more-btn {
    display: block;
    width: 120px;
    margin: 0.5rem auto 0;
    padding: 0.6rem 1rem;
    background: var(--primary-blue);
    color: white;
    border: none;
    border-radius: 20px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: all 0.3s ease;
    order: 1; /* 确保按钮在最下面 */
  }

  .read-more-btn:hover {
    background: var(--primary-green);
    transform: translateY(-2px);
  }

  .read-more-btn.expanded {
    background: var(--text-light);
  }

  /* 调整卡片间距 */
  .directors-grid {
    gap: 2rem;
  }

  /* Logo部分适配 */
  .logo-content {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .meaning-logo {
    max-width: 280px;
    margin: 0 auto;
  }

  .logo-info {
    text-align: center;
  }

  .logo-info h2 {
    font-size: 1.6rem;
  }

  /* Brandmark部分适配 */
  .brandmark {
    padding: 1.5rem;
  }

  .brandmark-main {
    width: 100%;
  }

  .brandmark-details {
    flex-direction: column;
    gap: 1.5rem;
  }

  .brandmark-detail {
    width: 100%;
  }

  /* Vision & Mission部分适配 */
  .vision-mission-content {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .vision, .mission {
    padding: 1.5rem;
  }

  .vision h2, .mission h2 {
    font-size: 1.6rem;
  }

  /* Core Values部分适配 */
  .values-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .value-item {
    padding: 1.5rem;
  }

  .value-item h3 {
    font-size: 1.4rem;
  }

  /* 公司历史部分适配 */
  .company-history-section {
    padding: 3rem 1rem;
  }

  .history-title {
    font-size: 1.6rem;
    margin-bottom: 2rem;
  }

  .history-paragraph {
    font-size: 1rem;
    line-height: 1.6;
  }

  /* 公司集团部分适配 */
  .company-group-section h2 {
    font-size: 1.6rem;
  }

  .company-item {
    padding: 1rem 1.5rem;
  }

  .company-item h3 {
    font-size: 1.1rem;
  }
}

/* 添加平板端适配 */
@media (min-width: 769px) and (max-width: 1024px) {
  .director-card {
    grid-template-columns: 250px 1fr;
    gap: 2rem;
  }

  .director-image {
    width: 250px;
    height: 320px;
  }

  .director-info {
    height: 320px;
  }

  .logo-content {
    gap: 3rem;
  }

  .meaning-logo {
    max-width: 300px;
  }

  .vision-mission-content {
    gap: 2rem;
  }
}

/* 默认隐藏查看更多按钮 */
.read-more-btn {
  display: none;
}

/* 移动端显示查看更多按钮 */
@media (max-width: 768px) {
  .read-more-btn {
    display: block;
    width: 120px;
    margin: 0.5rem auto 0;
    padding: 0.6rem 1rem;
    background: var(--primary-blue);
    color: white;
    border: none;
    border-radius: 20px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: all 0.3s ease;
    order: 1;
  }

  .read-more-btn:hover {
    background: var(--primary-green);
    transform: translateY(-2px);
  }

  .read-more-btn.expanded {
    background: var(--text-light);
  }

  /* 移动端内容限制高度 */
  .director-info {
    width: 100%;
    height: auto;
    max-height: 200px;
    overflow: hidden;
    transition: max-height 0.3s ease;
    position: relative;
    padding: 0 0.5rem;
  }

  .director-info.expanded {
    max-height: none;
  }

  /* 移动端渐变遮罩 */
  .director-info::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background: linear-gradient(transparent, white);
    pointer-events: none;
    opacity: 1;
    transition: opacity 0.3s ease;
  }

  .director-info.expanded::after {
    opacity: 0;
  }
}

/* 电脑端样式 */
@media (min-width: 769px) {
  .director-card {
    display: grid;
    grid-template-columns: 300px 1fr;
    gap: 4rem;
    padding: 3rem;
  }

  .director-image {
    width: 300px;
    height: 380px;
    object-fit: cover;
    border-radius: 10px;
  }

  .director-info {
    height: 380px;
    overflow-y: auto;
    padding-right: 1rem;
    /* 自定义滚动条样式 */
    scrollbar-width: thin;
    scrollbar-color: var(--primary-blue) #f0f0f0;
  }

  .director-info::-webkit-scrollbar {
    width: 6px;
  }

  .director-info::-webkit-scrollbar-track {
    background: #f0f0f0;
    border-radius: 3px;
  }

  .director-info::-webkit-scrollbar-thumb {
    background-color: var(--primary-blue);
    border-radius: 3px;
  }

  /* 移除电脑端的渐变遮罩 */
  .director-info::after {
    display: none;
  }
}

/* 平板端适配 */
@media (min-width: 769px) and (max-width: 1024px) {
  .director-card {
    grid-template-columns: 250px 1fr;
    gap: 2rem;
  }

  .director-image {
    width: 250px;
    height: 320px;
  }

  .director-info {
    height: 320px;
  }
}

/* 添加 Our Journey 相关样式 */
.company-history-section {
  padding: 6rem 0 4rem;
  background: white;
}

.history-content {
  position: relative;
  max-width: 900px;
  margin: 0 auto;
}

.history-paragraphs {
  transition: max-height 0.3s ease;
}

/* 移动端样式 */
@media (max-width: 768px) {
  .history-paragraphs {
    max-height: 300px;
    overflow: hidden;
    position: relative;
  }

  .history-paragraphs.expanded {
    max-height: none;
  }

  /* 渐变遮罩 */
  .history-paragraphs::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background: linear-gradient(transparent, white);
    pointer-events: none;
    opacity: 1;
    transition: opacity 0.3s ease;
  }

  .history-paragraphs.expanded::after {
    opacity: 0;
  }

  /* Journey 查看更多按钮 */
  .journey-read-more {
    display: block;
    width: 120px;
    margin: 1rem auto 0;
    padding: 0.6rem 1rem;
    background: var(--primary-blue);
    color: white;
    border: none;
    border-radius: 20px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .journey-read-more:hover {
    background: var(--primary-green);
    transform: translateY(-2px);
  }

  .journey-read-more.expanded {
    background: var(--text-light);
  }
}

/* 电脑端样式 */
@media (min-width: 769px) {
  .journey-read-more {
    display: none;
  }

  .history-paragraphs::after {
    display: none;
  }
}

/* Logo Section 移动端适配 */
@media (max-width: 768px) {
  .logo-content {
    grid-template-columns: 1fr;
    gap: 2rem;
    text-align: center;
  }

  .logo-image {
    margin: 0 auto;
  }

  .meaning-logo {
    max-width: 250px;
    margin: 0 auto;
  }

  .logo-info {
    padding: 0 1rem;
  }

  .logo-info h2 {
    font-size: 1.6rem;
    margin-bottom: 1.5rem;
  }

  .logo-info p {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 1rem;
  }

  /* Brandmark 部分移动端优化 */
  .brandmark {
    padding: 2rem 1rem;
    margin-top: 2rem;
  }

  .brandmark h3 {
    font-size: 1.6rem;
    margin-bottom: 2rem;
  }

  .brandmark-main {
    max-width: 280px;
    margin-bottom: 2rem;
  }

  .brandmark p {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 2rem;
    padding: 0 1rem;
  }
} 