/* 基础样式 */
.adoption-page {
  padding-top: 90px;
}

/* Hero Section */
.adoption-hero {
  background: linear-gradient(135deg, 
    rgba(102,189,64,0.05),
    rgba(56,119,186,0.05)
  );
  padding: 8rem 0;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.adoption-hero h1 {
  font-size: 3.5rem;
  color: var(--text-dark);
  margin-bottom: 2rem;
  font-weight: 800;
}

.hero-subtitle {
  font-size: 1.4rem;
  color: var(--text-light);
  margin-bottom: 1rem;
}

.hero-highlight {
  font-size: 2rem;
  color: var(--primary-green);
  font-weight: 700;
  margin-bottom: 2rem;
  position: relative;
  display: inline-block;
}

.hero-highlight::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 4px;
  background: var(--primary-green);
  opacity: 0.2;
  border-radius: 2px;
}

.hero-marquee {
  background: var(--primary-green);
  color: white;
  padding: 1rem 0;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
}

.hero-marquee span {
  display: inline-block;
  animation: marquee 20s linear infinite;
  padding-left: 100%;
}

@keyframes marquee {
  0% { transform: translate(0, 0); }
  100% { transform: translate(-100%, 0); }
}

/* CTA Section */
.cta-section {
  padding: 6rem 0;
  background: white;
  text-align: center;
}

.cta-section h2 {
  font-size: 2.5rem;
  color: var(--text-dark);
  margin-bottom: 3rem;
}

.benefits-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-bottom: 3rem;
}

.benefit-item {
  background: white;
  padding: 2rem;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0,0,0,0.05);
  transition: transform 0.3s ease;
  border: 1px solid rgba(102,189,64,0.1);
}

.benefit-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 30px rgba(0,0,0,0.1);
}

.check-icon {
  font-size: 2rem;
  margin-bottom: 1rem;
  display: block;
}

.cta-note {
  font-size: 1.2rem;
  color: var(--text-light);
  margin-bottom: 2rem;
}

.cta-button {
  background: var(--gradient-primary);
  color: white;
  border: none;
  padding: 1.2rem 2.5rem;
  border-radius: 12px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(102,189,64,0.3);
}

.cta-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 25px rgba(102,189,64,0.4);
}

/* Scenarios Section */
.scenarios-section {
  padding: 6rem 0;
  background: #f8f9fa;
}

.scenarios-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.scenario-card {
  background: white;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: var(--shadow-md);
  transition: all 0.3s ease;
}

.scenario-card:hover {
  transform: translateY(-10px);
  box-shadow: var(--shadow-lg);
}

.scenario-image {
  width: 100%;
  aspect-ratio: 1 / 1;
  overflow: hidden;
  position: relative;
}

.scenario-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.scenario-card:hover .scenario-image img {
  transform: scale(1.05);
}

.scenario-card h3 {
  padding: 1.5rem 1.5rem 0.5rem;
  font-size: 1.3rem;
  color: var(--text-dark);
}

.scenario-card p {
  padding: 0 1.5rem 1.5rem;
  color: var(--text-light);
  font-size: 1rem;
  line-height: 1.6;
}

/* Emergency Slider样式更新 */
.emergency-slider {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.slider-content {
  display: flex;
  transition: transform 0.5s ease;
  height: 100%;
}

.slide {
  position: relative;
  flex: 0 0 100%;
  height: 100%;
}

.slide::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.slide-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slide-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 1.8rem;
  font-weight: 700;
  text-align: center;
  z-index: 2;
  width: 100%;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  letter-spacing: 1px;
}

.slide:hover .slide-text {
  transform: translate(-50%, -50%) scale(1.1);
  background: rgba(0, 0, 0, 0.8);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.slider-dots {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 0.5rem;
  z-index: 2;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
  border: none;
  cursor: pointer;
  padding: 0;
  transition: all 0.3s ease;
}

.dot.active {
  background: white;
  transform: scale(1.2);
}

/* 响应式调整 */
@media (max-width: 1024px) {
  .scenarios-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .scenarios-grid {
    grid-template-columns: 1fr;
  }

  .slide-text {
    font-size: 1.4rem;
  }
}

/* What is Home Adoption Section样式 */
.what-is-section {
  padding: 6rem 0;
  background: #f8f9fa;
}

.what-is-section h2 {
  text-align: center;
  font-size: 2.5rem;
  color: var(--text-dark);
  margin-bottom: 3rem;
}

.model-explanation {
  max-width: 800px;
  margin: 0 auto;
  padding: 3rem;
  background: white;
  border-radius: 20px;
  box-shadow: var(--shadow-md);
  transition: all 0.3s ease;
}

.model-explanation:hover {
  transform: translateY(-10px);
  box-shadow: var(--shadow-lg);
}

.model-explanation p {
  text-align: center;
  font-size: 1.2rem;
  color: var(--text-dark);
  margin-bottom: 2rem;
  font-weight: 500;
}

.model-comparison {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.model-comparison p {
  padding: 1.5rem;
  background: linear-gradient(135deg,
    rgba(102,189,64,0.05),
    rgba(56,119,186,0.05)
  );
  border-radius: 12px;
  font-size: 1.1rem;
  color: var(--text-dark);
  text-align: left;
  border: 1px solid rgba(102,189,64,0.1);
  transition: all 0.3s ease;
}

.model-comparison p:hover {
  transform: translateX(10px);
  background: linear-gradient(135deg,
    rgba(102,189,64,0.1),
    rgba(56,119,186,0.1)
  );
}

/* 响应式调整 */
@media (max-width: 768px) {
  .what-is-section {
    padding: 4rem 0;
  }

  .what-is-section h2 {
    font-size: 2rem;
  }

  .model-explanation {
    padding: 2rem;
    margin: 0 1rem;
  }

  .model-explanation p {
    font-size: 1.1rem;
  }

  .model-comparison p {
    font-size: 1rem;
    padding: 1.2rem;
  }
}

/* Video Section样式优化 */
.video-section {
  padding: 6rem 0;
  background: linear-gradient(135deg, 
    rgba(102,189,64,0.05),
    rgba(56,119,186,0.05)
  );
  position: relative;
  overflow: hidden;
}

.video-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: radial-gradient(circle at 1px 1px, rgba(0, 0, 0, 0.05) 1px, transparent 0);
  background-size: 20px 20px;
  opacity: 0.5;
}

.video-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 0 2rem;
  position: relative;
}

.video-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
  background: white;
  transition: all 0.3s ease;
}

.video-wrapper:hover {
  transform: translateY(-10px);
  box-shadow: 0 30px 60px rgba(0, 0, 0, 0.2);
}

.video-frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

/* 装饰元素 */
.video-decoration {
  position: absolute;
  border-radius: 50%;
  background: linear-gradient(135deg, var(--primary-green), var(--primary-blue));
  opacity: 0.1;
  filter: blur(50px);
}

.decoration-1 {
  width: 300px;
  height: 300px;
  top: -150px;
  right: -150px;
}

.decoration-2 {
  width: 200px;
  height: 200px;
  bottom: -100px;
  left: -100px;
}

/* 响应式调整 */
@media (max-width: 768px) {
  .video-section {
    padding: 4rem 0;
  }

  .video-container {
    padding: 0 1rem;
  }

  .video-wrapper {
    border-radius: 12px;
  }
}

/* Benefits Section样式更新 */
.adopter-benefits,
.owner-benefits {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  align-items: center;
  margin-bottom: 4rem;
  background: white;
  border-radius: 30px;
  overflow: hidden;
  box-shadow: var(--shadow-md);
}

.benefits-image {
  width: 100%;
  aspect-ratio: 1 / 1;
  position: relative;
  overflow: hidden;
}

.benefits-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 2rem;
  background: #f8f9fa;
  transition: transform 0.3s ease;
}

.benefits-content {
  padding: 3rem;
}

.benefits-header {
  margin-bottom: 2rem;
}

.benefits-badge {
  display: inline-block;
  padding: 0.8rem 1.5rem;
  background: linear-gradient(135deg,
    rgba(102,189,64,0.1),
    rgba(56,119,186,0.1)
  );
  border-radius: 50px;
  color: var(--primary-green);
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.benefits-header h3 {
  font-size: 1.8rem;
  color: var(--text-dark);
  line-height: 1.4;
}

.benefits-list {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.benefit-item {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  padding: 1.5rem;
  background: #f8f9fa;
  border-radius: 15px;
  transition: all 0.3s ease;
}

.benefit-item:hover {
  transform: translateX(10px);
  background: linear-gradient(135deg,
    rgba(102,189,64,0.1),
    rgba(56,119,186,0.1)
  );
}

.benefit-icon {
  font-size: 1.5rem;
  flex-shrink: 0;
}

.benefit-item p {
  font-size: 1rem;
  line-height: 1.6;
  color: var(--text-dark);
}

.benefits-note {
  text-align: center;
  padding: 2rem;
  background: linear-gradient(135deg,
    rgba(102,189,64,0.05),
    rgba(56,119,186,0.05)
  );
  border-radius: 15px;
}

.benefits-note p {
  color: var(--text-light);
  font-style: italic;
  margin-bottom: 1.5rem;
}

.cta-button {
  background: var(--gradient-primary);
  color: white;
  border: none;
  padding: 1rem 2rem;
  border-radius: 12px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.cta-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(102,189,64,0.2);
}

/* 响应式调整 */
@media (max-width: 1024px) {
  .adopter-benefits,
  .owner-benefits {
    grid-template-columns: 1fr;
  }

  .benefits-image {
    max-width: 500px;
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  .benefits-image {
    max-width: 100%;
  }

  .benefits-content {
    padding: 2rem;
  }
}

/* Financial Disclaimer Section样式 */
.disclaimer-section {
  padding: 6rem 0;
  background: #f8f9fa;
}

.disclaimer-section h2 {
  font-size: 2.2rem;
  color: var(--text-dark);
  margin-bottom: 3rem;
  text-align: center;
}

.disclaimer-content {
  max-width: 900px;
  margin: 0 auto;
  padding: 3rem;
  background: white;
  border-radius: 20px;
  box-shadow: var(--shadow-md);
}

.disclaimer-intro {
  margin-bottom: 3rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.disclaimer-intro p {
  font-size: 1.1rem;
  line-height: 1.8;
  color: var(--text-dark);
}

.disclaimer-block {
  margin-bottom: 2.5rem;
}

.disclaimer-block h3 {
  font-size: 1.4rem;
  color: var(--text-dark);
  margin-bottom: 1rem;
}

.disclaimer-block p {
  font-size: 1rem;
  line-height: 1.8;
  color: var(--text-light);
}

/* 响应式调整 */
@media (max-width: 768px) {
  .disclaimer-section {
    padding: 4rem 0;
  }

  .disclaimer-section h2 {
    font-size: 1.8rem;
  }

  .disclaimer-content {
    padding: 2rem;
    margin: 0 1rem;
  }

  .disclaimer-block h3 {
    font-size: 1.2rem;
  }
} 