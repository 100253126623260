/* 基础样式 */
.advance-page {
  padding-top: 90px;
}

/* Hero Section */
.advance-hero {
  position: relative;
  min-height: 60vh;
  background: linear-gradient(135deg, rgba(102,189,64,0.1), rgba(56,119,186,0.1));
  padding: 6rem 0;
  text-align: center;
  overflow: hidden;
}

.advance-hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: radial-gradient(circle at 1px 1px, rgba(0,0,0,0.05) 1px, transparent 0);
  background-size: 20px 20px;
  opacity: 0.5;
}

.advance-hero h1 {
  font-size: 3rem;
  color: var(--text-dark);
  margin-bottom: 1.5rem;
  position: relative;
}

.hero-subtitle {
  font-size: 1.5rem;
  color: var(--primary-green);
  margin-bottom: 2rem;
}

.learn-more-btn {
  background: var(--gradient-primary);
  color: white;
  border: none;
  padding: 1rem 2.5rem;
  border-radius: 50px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 10px 20px rgba(102,189,64,0.2);
  position: relative;
  z-index: 10;
}

.learn-more-btn:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(102,189,64,0.3);
  background: linear-gradient(135deg, 
    var(--primary-green), 
    var(--primary-blue)
  );
  cursor: pointer;
}

.learn-more-btn:active {
  transform: translateY(-2px);
  box-shadow: 0 10px 20px rgba(102,189,64,0.2);
}

/* Introduction Section */
.intro-section {
  padding: 6rem 0;
  background: white;
}

.intro-content {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.intro-content h2 {
  font-size: 2.2rem;
  color: var(--text-dark);
  margin-bottom: 2rem;
}

.intro-content p {
  font-size: 1.1rem;
  color: var(--text-light);
  line-height: 1.8;
  margin-bottom: 1.5rem;
}

/* FAQ Section */
.faq-section {
  padding: 6rem 0;
  background: #f8f9fa;
}

.faq-section h2 {
  text-align: center;
  font-size: 2.2rem;
  margin-bottom: 4rem;
}

.faq-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.faq-item {
  background: white;
  padding: 2rem;
  border-radius: 20px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.faq-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0,0,0,0.1);
}

.faq-item h3 {
  font-size: 1.2rem;
  color: var(--primary-green);
  margin-bottom: 1rem;
}

.faq-item p {
  color: var(--text-light);
  line-height: 1.8;
  font-size: 1rem;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .advance-hero h1 {
    font-size: 2.2rem;
  }

  .hero-subtitle {
    font-size: 1.2rem;
  }

  .intro-content h2,
  .faq-section h2 {
    font-size: 1.8rem;
  }

  .intro-content p {
    font-size: 1rem;
    padding: 0 1rem;
  }
} 