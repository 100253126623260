.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 2rem;
    border-radius: 20px;
    text-align: center;
    animation: modalShow 0.3s ease;
}

.success-icon {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: var(--primary-green);
    color: white;
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 1rem;
}

.modal-button {
    margin-top: 1.5rem;
    padding: 0.8rem 2rem;
    background: var(--gradient-primary);
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.modal-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(102, 189, 64, 0.3);
}

@keyframes modalShow {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}