.contact-page {
  width: 100%;
  overflow: hidden;
}

/* 英雄区域 */
.contact-hero {
  background: linear-gradient(135deg, #1a365d 0%, #2d4a8a 100%);
  padding: 100px 24px;
  text-align: center;
  color: white;
}

.contact-hero h1 {
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 16px;
}

.contact-hero p {
  font-size: 20px;
  max-width: 600px;
  margin: 0 auto;
  opacity: 0.9;
}

.contact-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 60px 24px;
}

/* 联系信息卡片 */
.contact-info-section {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-bottom: 60px;
}

.info-card {
  background: white;
  padding: 30px;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  text-align: center;
  transition: transform 0.3s ease;
}

.info-card:hover {
  transform: translateY(-5px);
}

.info-icon {
  font-size: 32px;
  margin-bottom: 20px;
}

.info-card h3 {
  font-size: 20px;
  color: #1a365d;
  margin-bottom: 12px;
}

.info-card p {
  color: #4a5568;
  line-height: 1.6;
}

/* 联系表单区域 */
.contact-form-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  margin-bottom: 60px;
  background: white;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
}

.form-container {
  padding: 40px;
}

.form-container h2 {
  font-size: 32px;
  color: #1a365d;
  margin-bottom: 30px;
}

.form-group {
  margin-bottom: 20px;
}

.form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

input, textarea {
  width: 100%;
  padding: 12px 16px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

input:focus, textarea:focus {
  outline: none;
  border-color: #2d4a8a;
}

.submit-button {
  background: #1a365d;
  color: white;
  padding: 14px 30px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background: #2d4a8a;
}

.form-image {
  height: 100%;
}

.form-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* 地图区域 */
.map-section {
  margin-bottom: 60px;
}

.map-container {
  background: white;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
}

.map-header {
  display: flex;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #edf2f7;
}

.location-icon {
  font-size: 24px;
  margin-right: 12px;
}

.map-header h3 {
  font-size: 20px;
  color: #1a365d;
  margin: 0;
}

/* 隐藏Google地图的默认UI元素 */
iframe {
  filter: grayscale(0.1) contrast(1.2);
}

/* 确保地图容器有正确的宽高比 */
.map-container iframe {
  display: block;
  width: 100%;
  height: 450px;
  border: none;
}

/* 响应式调整 */
@media (max-width: 768px) {
  .map-container iframe {
    height: 350px;
  }
}

@media (max-width: 480px) {
  .map-container iframe {
    height: 300px;
  }
  
  .map-header {
    padding: 16px;
  }
  
  .map-header h3 {
    font-size: 18px;
  }
}

/* 响应式设计 */
@media (max-width: 1024px) {
  .contact-info-section {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .contact-hero {
    padding: 60px 24px;
  }

  .contact-hero h1 {
    font-size: 36px;
  }

  .contact-info-section {
    grid-template-columns: 1fr;
  }

  .contact-form-section {
    grid-template-columns: 1fr;
  }

  .form-image {
    display: none;
  }
}

@media (max-width: 480px) {
  .contact-hero h1 {
    font-size: 28px;
  }

  .form-row {
    grid-template-columns: 1fr;
  }

  .contact-container {
    padding: 40px 16px;
  }
}

/* 添加WhatsApp相关样式 */
.clickable {
  cursor: pointer;
}

.whatsapp-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  padding: 8px 16px;
  background: #25D366;
  color: white;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.whatsapp-button:hover {
  background: #128C7E;
}

.whatsapp-icon {
  width: 24px;
  height: 24px;
  fill: currentColor;
  margin-right: 8px;
}

/* 添加悬浮WhatsApp按钮 */
.floating-whatsapp {
  position: fixed;
  bottom: 24px;
  right: 24px;
  display: flex;
  align-items: center;
  padding: 12px 24px;
  background: #25D366;
  color: white;
  border: none;
  border-radius: 50px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 1000;
}

.floating-whatsapp:hover {
  background: #128C7E;
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
}

.floating-whatsapp .whatsapp-icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

/* 响应式调整 */
@media (max-width: 768px) {
  .floating-whatsapp span {
    display: none;
  }

  .floating-whatsapp {
    padding: 12px;
  }

  .floating-whatsapp .whatsapp-icon {
    margin-right: 0;
  }
} 