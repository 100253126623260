.career-page {
  padding-top: 90px;
}

/* Hero Section */
.career-hero {
  background: linear-gradient(135deg, rgba(102,189,64,0.1), rgba(56,119,186,0.1));
  padding: 6rem 0;
  text-align: center;
}

.career-hero h1 {
  font-size: 2.5rem;
  color: var(--text-dark);
  margin-bottom: 1.5rem;
}

.career-hero p {
  font-size: 1.5rem;
  color: var(--primary-green);
}

/* Job Details Section */
.job-details {
  max-width: 1000px;
  margin: 4rem auto;
  padding: 0 2rem;
}

.requirements, .responsibilities, .benefits, .additional-benefits {
  background: white;
  border-radius: 20px;
  padding: 2rem;
  margin-bottom: 2rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
}

.job-details h2 {
  font-size: 1.8rem;
  color: var(--primary-blue);
  margin-bottom: 1.5rem;
}

.job-details ul {
  list-style: none;
  padding: 0;
}

.job-details li {
  position: relative;
  padding-left: 1.5rem;
  margin-bottom: 1rem;
  color: var(--text-light);
  line-height: 1.6;
}

.job-details li::before {
  content: "•";
  color: var(--primary-green);
  position: absolute;
  left: 0;
  font-weight: bold;
}

/* Application Form */
.application-form {
  max-width: 800px;
  margin: 4rem auto;
  padding: 2rem;
  background: white;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
}

.application-form h2 {
  font-size: 2rem;
  color: var(--text-dark);
  margin-bottom: 2rem;
  text-align: center;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: var(--text-dark);
  font-weight: 500;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 0.8rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.form-group input:focus,
.form-group select:focus {
  outline: none;
  border-color: var(--primary-green);
  box-shadow: 0 0 0 2px rgba(102,189,64,0.1);
}

.form-group input[type="file"] {
  padding: 0.5rem;
  border: 2px dashed rgba(0, 0, 0, 0.1);
  background: #f8f9fa;
  margin-bottom: 0;
}

.submit-button {
  width: 100%;
  padding: 1rem;
  background: var(--gradient-primary);
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.submit-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(102,189,64,0.3);
}

/* 响应式设计 */
@media (max-width: 768px) {
  .career-hero h1 {
    font-size: 2rem;
  }

  .career-hero p {
    font-size: 1.2rem;
  }

  .job-details {
    padding: 0 1rem;
  }

  .requirements, .responsibilities, .benefits, .additional-benefits {
    padding: 1.5rem;
  }

  .application-form {
    padding: 1.5rem;
  }
}

.section-container {
  margin-bottom: 1rem;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  background: white;
  border-radius: 20px;
  cursor: pointer;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
}

.section-header:hover {
  transform: translateY(-2px);
}

.section-header.active {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background: var(--primary-green);
}

.section-header.active h2 {
  color: white;
}

.toggle-icon {
  font-size: 1.5rem;
  color: var(--primary-blue);
  transition: all 0.3s ease;
}

.section-header.active .toggle-icon {
  color: white;
  transform: rotate(180deg);
}

.section-content {
  padding: 2rem;
  background: white;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  animation: slideDown 0.3s ease-out;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.sections-nav {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 2rem;
}

.section-tab {
  flex: 1;
  padding: 1.2rem;
  background: white;
  border-radius: 12px;
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
}

.section-tab h2 {
  font-size: 1.1rem;
  margin: 0;
  color: var(--text-dark);
  transition: all 0.3s ease;
}

.section-tab:hover {
  transform: translateY(-3px);
}

.section-tab.active {
  background: var(--primary-green);
}

.section-tab.active h2 {
  color: white;
}

.section-content-wrapper {
  min-height: 300px;
}

.section-content {
  background: white;
  border-radius: 20px;
  padding: 2rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  animation: fadeIn 0.3s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* 响应式调整 */
@media (max-width: 768px) {
  .sections-nav {
    flex-direction: column;
    gap: 0.5rem;
  }

  .section-tab {
    padding: 1rem;
  }

  .section-tab h2 {
    font-size: 1rem;
  }
}

.form-hint {
  display: block;
  margin-top: 0.5rem;
  color: var(--text-light);
  font-size: 0.9rem;
  font-style: italic;
} 