.rental-page {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  overflow: hidden;
}

/* 英雄区域 */
.rental-hero {
  position: relative;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #f6f9fc 0%, #eef2f7 100%);
  padding: 60px 24px;
}

.hero-content {
  text-align: center;
  max-width: 800px;
}

.rental-hero h1 {
  font-size: 48px;
  font-weight: 700;
  color: #1a365d;
  margin-bottom: 24px;
  line-height: 1.2;
}

.rental-hero .subtitle {
  font-size: 24px;
  color: #4a5568;
  line-height: 1.6;
}

/* 特色区域 */
.rental-features {
  padding: 80px 0;
  background: #ffffff;
}

.features-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  gap: 30px;
  padding: 0 24px;
}

.feature-card {
  flex: 1;
  background: #ffffff;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
}

.feature-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.12);
}

.card-image {
  position: relative;
  width: 100%;
  height: auto;
  padding-top: 66.67%;
  overflow: hidden;
}

.card-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: #f8f9fa;
  padding: 16px;
  transition: transform 0.3s ease;
}

.feature-card:hover .card-image img {
  transform: scale(1.05);
}

.card-content {
  padding: 24px;
  background: #ffffff;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-content h3 {
  font-size: 24px;
  font-weight: 600;
  color: #2d3748;
  margin-bottom: 16px;
  text-align: left;
}

.card-content p {
  font-size: 16px;
  color: #4a5568;
  line-height: 1.6;
  text-align: left;
}

/* 对比区域 */
.rental-comparison {
  padding: 80px 24px;
  background: #f7fafc;
}

.rental-comparison h2 {
  font-size: 36px;
  font-weight: 700;
  color: #1a365d;
  text-align: center;
  margin-bottom: 8px;
}

.rental-comparison h3 {
  font-size: 24px;
  color: #4a5568;
  text-align: center;
  margin-bottom: 48px;
}

.comparison-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  max-width: 1000px;
  margin: 0 auto 40px;
}

.comparison-card {
  background: #ffffff;
  border-radius: 16px;
  padding: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.comparison-card img {
  width: 100%;
  height: auto;
  object-fit: contain;
  background-color: #f8f9fa;
  padding: 16px;
  border-radius: 12px;
  margin-bottom: 20px;
  max-height: 300px;
}

.comparison-card h4 {
  font-size: 20px;
  font-weight: 600;
  color: #2d3748;
  text-align: center;
}

.comparison-text {
  max-width: 800px;
  margin: 0 auto;
  font-size: 16px;
  color: #4a5568;
  line-height: 1.8;
  text-align: center;
}

/* 结束区域 */
.rental-conclusion {
  padding: 80px 24px;
  text-align: center;
  background: #ffffff;
}

.rental-conclusion h2 {
  font-size: 36px;
  font-weight: 700;
  color: #1a365d;
  margin-bottom: 16px;
}

.rental-conclusion p {
  font-size: 18px;
  color: #4a5568;
  max-width: 600px;
  margin: 0 auto 40px;
  line-height: 1.6;
}

.conclusion-logo {
  width: 400px;
  height: auto;
  margin: 0 auto;
  display: block;
  transition: transform 0.3s ease;
}

.conclusion-logo:hover {
  transform: scale(1.05);
}

/* 响应式设计 */
@media (max-width: 1024px) {
  .features-container {
    flex-direction: column;
    max-width: 600px;
  }

  .feature-card {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .rental-hero {
    min-height: 300px;
    padding: 40px 24px;
  }

  .rental-hero h1 {
    font-size: 36px;
  }

  .rental-hero .subtitle {
    font-size: 20px;
  }

  .card-image {
    padding-top: 56.25%;
  }

  .comparison-container {
    grid-template-columns: 1fr;
    gap: 24px;
  }

  .rental-comparison h2 {
    font-size: 28px;
  }

  .rental-comparison h3 {
    font-size: 20px;
  }

  .comparison-card img {
    max-height: 250px;
  }

  .conclusion-logo {
    width: 300px;
  }
}

@media (max-width: 480px) {
  .rental-hero h1 {
    font-size: 28px;
  }

  .rental-hero .subtitle {
    font-size: 18px;
  }

  .card-image {
    padding-top: 75%;
  }

  .card-content {
    padding: 20px;
  }

  .card-content h3 {
    font-size: 20px;
  }

  .feature-card,
  .comparison-card {
    padding: 20px;
  }

  .feature-card img {
    height: 160px;
  }

  .comparison-card img {
    max-height: 200px;
  }

  .conclusion-logo {
    width: 250px;
  }
} 