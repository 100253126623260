:root {
  --primary-green: #66BD40;
  --primary-blue: #3877BA;
  --text-dark: #333;
  --text-light: #666;
  --gradient-primary: linear-gradient(135deg, var(--primary-green), var(--primary-blue));
  --shadow-sm: 0 2px 4px rgba(0, 0, 0, 0.1);
  --shadow-md: 0 4px 6px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0 10px 15px rgba(0, 0, 0, 0.1);
  --transition: all 0.3s ease;
}

/* 全局样式 */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  line-height: 1.6;
  color: var(--text-dark);
}

/* 导航栏样式优化 */
.nav-bar {
  background: rgba(255, 255, 255, 0.98);
  backdrop-filter: blur(10px);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.08);
  padding: 0.5rem 0;
}

.nav-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
}

.nav-left {
  display: flex;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
  gap: 1rem;
  text-decoration: none;
  transition: var(--transition);
}

.logo:hover {
  opacity: 0.9;
  transform: translateY(-1px);
}

.logo-image {
  height: 60px;
  width: auto;
  object-fit: contain;
  margin: -15px 0;
}

.logo-text {
  display: flex;
  align-items: center;
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: -0.5px;
}

.logo-text-blue,
.logo-text-green {
  transition: var(--transition);
}

.logo:hover .logo-text-blue {
  color: var(--primary-blue);
}

.logo:hover .logo-text-green {
  color: var(--primary-green);
}

.nav-right {
  display: flex;
  align-items: center;
  gap: 3rem;
}

.nav-links {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.nav-link {
  color: var(--text-dark);
  text-decoration: none;
  font-weight: 500;
  font-size: 1rem;
  padding: 0.4rem 0;
  position: relative;
  transition: color 0.3s ease;
}

.nav-link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background: var(--gradient-primary);
  transition: width 0.3s ease;
}

.nav-link:hover {
  color: var(--primary-green);
}

.nav-link:hover::after {
  width: 100%;
}

.nav-buttons {
  display: flex;
  gap: 1.2rem;
}

.nav-button {
  padding: 0.6rem 1.2rem;
  border-radius: 8px;
  font-weight: 600;
  font-size: 0.95rem;
  transition: all 0.3s ease;
  cursor: pointer;
}

.nav-button.primary {
  background: var(--gradient-primary);
  color: white;
  border: none;
  box-shadow: 0 2px 8px rgba(102, 189, 64, 0.3);
}

.nav-button.secondary {
  background: transparent;
  color: var(--primary-green);
  border: 2px solid var(--primary-green);
}

.nav-button.primary:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(102, 189, 64, 0.4);
}

.nav-button.secondary:hover {
  background: rgba(102, 189, 64, 0.1);
}

/* 移端响应式设计 */
@media (max-width: 1200px) {
  .nav-container {
    padding: 0 2rem;
  }

  .nav-links {
    gap: 2rem;
  }
}

@media (max-width: 1024px) {
  .nav-container {
    padding: 0 1.5rem;
  }

  .nav-links {
    gap: 1.5rem;
  }

  .logo-image {
    height: 90px;
  }
}

@media (max-width: 768px) {
  .nav-right {
    display: flex;
    position: fixed;
    top: 0;
    right: -100%;
    width: 85%;
    max-width: 350px;
    height: 100vh;
    background: white;
    padding: 100px 2rem 2rem;
    flex-direction: column;
    gap: 2rem;
    transition: all 0.3s ease;
    box-shadow: -5px 0 15px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    z-index: 1000;
  }

  .nav-right.active {
    right: 0;
  }

  .nav-links {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 0;
  }

  .nav-link {
    width: 100%;
    padding: 1rem;
    border-radius: 8px;
    transition: all 0.3s ease;
  }

  .nav-link:hover {
    background: rgba(102, 189, 64, 0.1);
    transform: translateX(5px);
  }

  .nav-buttons {
    flex-direction: column;
    width: 100%;
    gap: 1rem;
  }

  .nav-button {
    width: 100%;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* 下拉菜单样式优化 */
  .dropdown {
    width: 100%;
  }

  .dropdown-toggle {
    width: 100%;
    padding: 1rem;
    justify-content: space-between;
    border-radius: 8px;
  }

  .dropdown-toggle:hover {
    background: rgba(102, 189, 64, 0.1);
  }

  .dropdown-menu {
    position: static;
    width: 100%;
    opacity: 1;
    visibility: visible;
    box-shadow: none;
    background: transparent;
    padding: 0;
    margin: 0;
    margin-left: 1rem;
  }

  .dropdown-menu::before {
    display: none;
  }

  .dropdown-item {
    padding: 1rem;
    margin: 0.2rem 0;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(102, 189, 64, 0.1);
  }

  .dropdown-item:hover {
    background: rgba(102, 189, 64, 0.1);
    transform: translateX(5px);
  }

  /* 语言切换按钮样式优化 */
  .language-dropdown {
    width: 100%;
    margin: 0;
  }

  .language-btn {
    width: 100%;
    height: auto;
    border-radius: 8px;
    padding: 1rem;
    display: flex;
    justify-content: center;
  }

  .language-menu {
    width: 100%;
    margin-top: 0.5rem;
  }

  .language-option {
    padding: 1rem;
    text-align: center;
  }

  /* 添加遮罩层 */
  .mobile-menu-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
    z-index: 999;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .mobile-menu-overlay.active {
    display: block;
    opacity: 1;
  }
}

/* Hero Section 样式更新 */
.hero-section {
  position: relative;
  min-height: 100vh;
  padding: 120px 0 80px;
  background: linear-gradient(135deg,
      rgba(102, 189, 64, 0.05) 0%,
      rgba(56, 119, 186, 0.05) 100%);
  overflow: hidden;
}

.hero-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 4rem;
  position: relative;
}

.hero-content {
  position: relative;
  z-index: 2;
}

.hero-badge {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.8rem 1.2rem;
  background: white;
  border-radius: 50px;
  margin-bottom: 2rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
}

.hero-title {
  font-size: 3.5rem;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  font-weight: 800;
}

.title-highlight {
  position: relative;
  color: var(--primary-green);
}

.title-highlight::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0.2rem;
  width: 100%;
  height: 0.5rem;
  background: var(--primary-green);
  opacity: 0.2;
  border-radius: 4px;
}

.hero-subtitle {
  font-size: 1.25rem;
  color: var(--text-light);
  margin-bottom: 2.5rem;
  line-height: 1.8;
}

.hero-visual {
  position: relative;
  width: 100%;
  height: 100%;
}

.image-container {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  filter: drop-shadow(0 20px 40px rgba(0, 0, 0, 0.15));
}

.image-background {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 95%;
  height: 95%;
  background: linear-gradient(135deg,
      rgba(102, 189, 64, 0.15),
      rgba(56, 119, 186, 0.15));
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  animation: morphBackground 10s ease-in-out infinite;
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.hero-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 1;
  border-radius: 20px;
  padding: 1rem;
}

.image-container::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at center,
      rgba(102, 189, 64, 0.2) 0%,
      rgba(56, 119, 186, 0.2) 50%,
      transparent 70%);
  filter: blur(40px);
  z-index: 0;
}

.decorative-circle {
  position: absolute;
  border-radius: 50%;
  opacity: 0.1;
  background: linear-gradient(135deg, var(--primary-green), var(--primary-blue));
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.circle-1 {
  width: 180px;
  height: 180px;
  top: -30px;
  right: -30px;
  animation: float 6s ease-in-out infinite;
  backdrop-filter: blur(5px);
}

.circle-2 {
  width: 140px;
  height: 140px;
  bottom: -20px;
  left: -20px;
  animation: float 8s ease-in-out infinite reverse;
  backdrop-filter: blur(5px);
}

.decorative-shape {
  position: absolute;
  background: linear-gradient(135deg,
      rgba(102, 189, 64, 0.1),
      rgba(56, 119, 186, 0.1));
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  border: 1px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(5px);
}

.shape-1 {
  width: 120px;
  height: 120px;
  top: 15%;
  right: -40px;
  animation: morphShape 8s ease-in-out infinite;
}

.shape-2 {
  width: 100px;
  height: 100px;
  bottom: 15%;
  left: -30px;
  animation: morphShape 10s ease-in-out infinite reverse;
}

@keyframes morphBackground {
  0% {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  }

  50% {
    border-radius: 70% 30% 30% 70% / 70% 70% 30% 30%;
  }

  100% {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  }
}

@keyframes float {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes morphShape {
  0% {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    transform: rotate(0deg);
  }

  50% {
    border-radius: 70% 30% 30% 70% / 70% 70% 30% 30%;
    transform: rotate(180deg);
  }

  100% {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    transform: rotate(360deg);
  }
}

/* 响应式调整 */
@media (max-width: 1200px) {
  .hero-container {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .hero-buttons {
    justify-content: center;
  }

  .hero-visual {
    max-width: 600px;
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  .hero-title {
    font-size: 2.5rem;
  }

  .hero-visual {
    max-width: 400px;
  }
}

/* 服务卡片样优化 */
.services-section {
  padding: 80px 0;
  background: #ffffff;
}

.services-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
}

.services-title {
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 16px;
  background: linear-gradient(135deg, #1a365d, #2d4a8a);
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
}

.services-subtitle {
  font-size: 18px;
  color: #4a5568;
  text-align: center;
  margin-bottom: 48px;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  max-width: 1000px;
  margin: 0 auto;
}

.service-card {
  background: #ffffff;
  border-radius: 20px;
  padding: 40px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.service-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(90deg, var(--primary-green), var(--primary-blue));
  opacity: 0;
  transition: opacity 0.3s ease;
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
}

.service-card:hover::before {
  opacity: 1;
}

.service-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
}

.service-number {
  font-size: 48px;
  font-weight: 700;
  background: linear-gradient(135deg, var(--primary-green), var(--primary-blue));
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  opacity: 0.2;
}

.service-icon {
  font-size: 36px;
  background: linear-gradient(135deg, rgba(102, 189, 64, 0.1), rgba(56, 119, 186, 0.1));
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
}

.service-title {
  font-size: 24px;
  font-weight: 600;
  color: #2d3748;
  margin-bottom: 16px;
}

.service-description {
  font-size: 16px;
  color: #4a5568;
  line-height: 1.6;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .services-grid {
    grid-template-columns: 1fr;
    gap: 24px;
  }

  .services-title {
    font-size: 28px;
  }

  .services-subtitle {
    font-size: 16px;
  }

  .service-card {
    padding: 30px;
  }

  .service-icon {
    width: 60px;
    height: 60px;
    font-size: 30px;
  }

  .service-number {
    font-size: 36px;
  }
}

/* 统计数据样式优化 */
.stats-section {
  background: linear-gradient(135deg, var(--primary-green), var(--primary-blue));
  position: relative;
  overflow: hidden;
}

.stats-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('./images/pattern.png');
  background-size: 300px;
  opacity: 0.1;
  animation: patternMove 30s linear infinite;
}

@keyframes patternMove {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 300px 300px;
  }
}

.stats-overlay {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 30px;
  padding: 4rem;
}

.stat-item {
  text-align: center;
}

.stat-number {
  font-size: 3.5rem;
  font-weight: 800;
  display: block;
  margin-bottom: 0.5rem;
  background: linear-gradient(135deg, #fff, rgba(255, 255, 255, 0.8));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* 按钮样式优化 */
.primary-button,
.secondary-button {
  padding: 1rem 2rem;
  border-radius: 10px;
  font-weight: 600;
  transition: var(--transition);
  border: none;
  cursor: pointer;
}

.primary-button {
  background: var(--gradient-primary);
  color: white;
  box-shadow: 0 4px 15px rgba(102, 189, 64, 0.3);
}

.secondary-button {
  background: white;
  color: var(--primary-green);
  border: 2px solid var(--primary-green);
}

.primary-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(102, 189, 64, 0.4);
}

/* 响应式设计优化 */
@media (max-width: 768px) {
  .hero-title {
    font-size: 2.5rem;
  }

  .nav-menu {
    display: none;
  }

  .stats-overlay {
    padding: 2rem;
  }

  .service-card {
    margin-bottom: 1rem;
  }
}

/* 见证部分样式 */
.testimonials-section {
  padding: 6rem 5%;
  background: #f8f9fa;
}

.testimonials-slider {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 0;
}

.testimonial-card {
  background: white;
  padding: 2rem;
  border-radius: 20px;
  box-shadow: var(--shadow-md);
  transition: var(--transition);
}

.testimonial-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-lg);
}

.testimonial-card p {
  color: var(--text-light);
  font-size: 1rem;
  line-height: 1.8;
  margin-bottom: 1.5rem;
}

.testimonial-author {
  color: var(--text-dark);
  font-weight: 600;
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
}

.testimonial-location {
  color: var(--text-light);
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .testimonials-slider {
    grid-template-columns: 1fr;
  }

  .testimonial-card {
    margin-bottom: 1rem;
  }
}

/* 英雄区域增强 */
.hero-trust-badges {
  display: flex;
  gap: 2rem;
  margin-top: 3rem;
}

.trust-badge {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
  color: var(--text-light);
}

.badge-icon {
  font-size: 1.2rem;
}

/* 按钮样式增强 */
.button-icon {
  margin-right: 8px;
}

.primary-button,
.secondary-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem;
  border-radius: 12px;
  font-weight: 600;
  transition: all 0.3s ease;
  min-width: 160px;
}

/* 服务卡片增强 */
.service-icon-wrapper {
  width: 60px;
  height: 60px;
  background: linear-gradient(135deg, rgba(102, 189, 64, 0.1), rgba(56, 119, 186, 0.1));
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.service-icon {
  font-size: 2rem;
}

.service-link {
  display: inline-flex;
  align-items: center;
  color: var(--primary-blue);
  text-decoration: none;
  font-weight: 500;
  margin-top: 1rem;
  transition: all 0.3s ease;
}

.service-link .arrow {
  margin-left: 8px;
  transition: transform 0.3s ease;
}

.service-link:hover .arrow {
  transform: translateX(5px);
}

/* 动画效果 */
@keyframes floating {
  0% {
    transform: translateY(0px) rotate(0deg);
  }

  50% {
    transform: translateY(-20px) rotate(1deg);
  }

  100% {
    transform: translateY(0px) rotate(0deg);
  }
}

/* 响应式优化 */
@media (max-width: 1024px) {
  .hero-section {
    flex-direction: column;
    text-align: center;
    padding-top: 8rem;
  }

  .hero-content {
    max-width: 100%;
  }

  .hero-image {
    margin-top: 2rem;
    max-width: 100%;
  }

  .hero-image img {
    max-width: 400px;
  }

  .hero-trust-badges {
    justify-content: center;
  }
}

@media (max-width: 768px) {

  .hero-section::before,
  .stats-section::before {
    background-size: 200px;
  }

  .hero-title {
    font-size: 2.5rem;
  }
}

/* 增强信任徽章样式 */
.hero-trust-badges {
  display: flex;
  gap: 2rem;
  margin-top: 3rem;
  flex-wrap: wrap;
}

.trust-badge {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
  color: var(--text-light);
  padding: 0.5rem 1rem;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  box-shadow: var(--shadow-sm);
  transition: var(--transition);
}

.trust-badge:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-md);
}

.badge-icon {
  font-size: 1.4rem;
}

/* 在App.css中添加下拉菜单样式 */
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--text-dark);
  padding: 0.8rem;
}

.dropdown-icon {
  font-size: 0.8rem;
  transition: transform 0.3s ease;
}

.dropdown:hover .dropdown-icon {
  transform: rotate(180deg);
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%) translateY(10px);
  background: white;
  min-width: 250px;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  z-index: 1000;
  padding: 0.8rem;
  margin-top: 0.5rem;
}

.dropdown:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  transform: translateX(-50%) translateY(0);
}

.dropdown-item {
  display: block;
  padding: 1rem 1.5rem;
  color: var(--text-dark);
  text-decoration: none;
  font-size: 1rem;
  border-radius: 8px;
  transition: all 0.2s ease;
  text-align: left;
  white-space: nowrap;
}

.dropdown-item:hover {
  background: linear-gradient(135deg,
      rgba(102, 189, 64, 0.1),
      rgba(56, 119, 186, 0.1));
  color: var(--primary-green);
  transform: translateX(5px);
}

/* 添加箭头指示器 */
.dropdown-menu::before {
  content: '';
  position: absolute;
  top: -6px;
  left: 50%;
  transform: translateX(-50%);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid white;
}

/* 响应式设计优化 */
@media (max-width: 768px) {
  .dropdown-menu {
    position: static;
    transform: none;
    box-shadow: none;
    background: rgba(255, 255, 255, 0.05);
    margin-top: 0.5rem;
    width: 100%;
  }

  .dropdown-menu::before {
    display: none;
  }

  .dropdown:hover .dropdown-menu {
    transform: none;
  }

  .dropdown-item {
    padding: 0.6rem 1rem;
  }
}

/* Footer 样式 */
.footer {
  background: #1a1a1a;
  color: #fff;
  padding: 3rem 0 1.5rem;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.footer-intro {
  max-width: 600px;
  margin-bottom: 3rem;
}

.footer-logo {
  height: 60px;
  margin-bottom: 1.5rem;
}

.footer-description {
  color: #999;
  line-height: 1.6;
  font-size: 0.95rem;
}

.footer-offices {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 3rem;
  margin-top: 3rem;
}

.footer-office {
  padding: 1.2rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  transition: var(--transition);
}

.footer-office:hover {
  background: rgba(255, 255, 255, 0.08);
  transform: translateY(-5px);
}

.footer-office h3 {
  color: var(--primary-green);
  margin-bottom: 0.8rem;
  font-size: 1.1rem;
}

.company-name {
  color: #fff;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.address {
  color: #999;
  line-height: 1.6;
  margin-bottom: 1rem;
  font-size: 0.9rem;
}

.contact-info {
  margin-bottom: 1.5rem;
}

.contact-info p {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.4rem;
  color: #999;
  font-size: 0.9rem;
}

.icon {
  font-size: 1.2rem;
}

.social-links {
  display: flex;
  gap: 1rem;
}

.social-link {
  color: #fff;
  text-decoration: none;
  padding: 0.4rem 1rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  font-size: 0.85rem;
  transition: var(--transition);
}

.social-link:hover {
  background: var(--primary-green);
  color: white;
}

.footer-bottom {
  margin-top: 3rem;
  padding-top: 1.5rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  text-align: center;
  color: #666;
  font-size: 0.85rem;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .footer-container {
    padding: 0 1rem;
  }

  .footer-offices {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .footer-office {
    padding: 1rem;
  }
}

/* 更新footer相关样式 */
.footer-brand {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.footer-logo {
  height: 60px;
  width: auto;
  object-fit: contain;
}

.footer-logo-text {
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: -0.5px;
}

.logo-text-blue {
  background: linear-gradient(135deg, #1a365d, #2d4a8a);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
}

.logo-text-green {
  color: var(--primary-green);
}

/* 响应式调整 */
@media (max-width: 768px) {
  .footer-brand {
    justify-content: center;
    gap: 1rem;
  }

  .footer-logo {
    height: 50px;
  }

  .footer-logo-text {
    font-size: 1.5rem;
  }
}

/* 修改社交媒体按钮样式 */
.social-buttons {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 2rem;
}

.social-link {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  padding: 0.8rem 1.5rem;
  border-radius: 12px;
  font-size: 1rem;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.3s ease;
}

.social-link.facebook {
  background: rgba(24, 119, 242, 0.1);
  color: #1877f2;
}

.social-link.whatsapp {
  background: rgba(37, 211, 102, 0.1);
  color: #25D366;
}

.social-link:hover {
  transform: translateY(-3px);
}

.social-link.facebook:hover {
  background: #1877f2;
  color: white;
}

.social-link.whatsapp:hover {
  background: #25D366;
  color: white;
}

.social-link svg {
  width: 20px;
  height: 20px;
  fill: currentColor;
}

/* 响应式调整 */
@media (max-width: 768px) {
  .social-buttons {
    flex-direction: row;
    gap: 1rem;
  }

  .social-link {
    flex: 1;
    padding: 0.8rem 1rem;
  }
}

/* 修改语言切换按钮样式 */
.language-dropdown {
  position: relative;
  margin-left: 1rem;
}

.language-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.7rem;
  border: 1px solid var(--primary-blue);
  border-radius: 50%;
  background: transparent;
  color: var(--primary-blue);
  cursor: pointer;
  transition: var(--transition);
  width: 40px;
  height: 40px;
}

.language-icon {
  width: 20px;
  height: 20px;
  fill: var(--primary-blue);
}

.language-btn:hover {
  background: rgba(56, 119, 186, 0.1);
  transform: translateY(-2px);
}

.dropdown-icon {
  display: none;
  /* 隐藏下拉箭头 */
}

.language-menu {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 0.8rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  z-index: 1000;
  min-width: 120px;
  overflow: hidden;
}

.language-dropdown:hover .language-menu {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.language-option {
  display: block;
  width: 100%;
  padding: 0.8rem 1.2rem;
  border: none;
  background: none;
  color: var(--text-dark);
  text-align: left;
  font-size: 0.9rem;
  cursor: pointer;
  transition: var(--transition);
}

.language-option:hover {
  background: rgba(56, 119, 186, 0.1);
}

.language-option.active {
  background: var(--primary-blue);
  color: white;
}

/* 添加箭头指示器 */
.language-menu::before {
  content: '';
  position: absolute;
  top: -6px;
  right: 15px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid white;
}

/* 应式调整 */
@media (max-width: 768px) {
  .language-dropdown {
    margin-left: 0;
    margin-top: 1rem;
  }

  .language-menu {
    position: static;
    opacity: 1;
    visibility: visible;
    box-shadow: none;
    margin-top: 0.5rem;
  }

  .language-menu::before {
    display: none;
  }
}

/* 添加移动端菜单按钮样式 */
.mobile-menu-button {
  display: none;
  background: none;
  border: none;
  padding: 10px;
  cursor: pointer;
  position: relative;
  z-index: 1001;
}

.menu-icon {
  display: block;
  width: 24px;
  height: 2px;
  background: var(--primary-blue);
  position: relative;
  transition: background 0.3s ease;
}

.menu-icon::before,
.menu-icon::after {
  content: '';
  position: absolute;
  width: 24px;
  height: 2px;
  background: var(--primary-blue);
  transition: transform 0.3s ease;
}

.menu-icon::before {
  top: -6px;
}

.menu-icon::after {
  bottom: -6px;
}

/* 移动端菜单打开时的图标动画 */
.mobile-menu-button.active .menu-icon {
  background: transparent;
}

.mobile-menu-button.active .menu-icon::before {
  transform: rotate(45deg);
  top: 0;
}

.mobile-menu-button.active .menu-icon::after {
  transform: rotate(-45deg);
  bottom: 0;
}

/* 移动端菜单样式优化 */
@media (max-width: 768px) {
  .mobile-menu-button {
    display: block;
    width: 40px;
    height: 40px;
    border: 1px solid var(--primary-blue);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: var(--transition);
  }

  .mobile-menu-button:hover {
    background: rgba(56, 119, 186, 0.1);
  }

  .menu-icon,
  .menu-icon::before,
  .menu-icon::after {
    width: 20px;
    background: var(--primary-blue);
  }

  .nav-right {
    position: fixed;
    top: 0;
    right: -100%;
    width: 85%;
    max-width: 350px;
    height: 100vh;
    background: white;
    padding: 100px 2rem 2rem;
    flex-direction: column;
    gap: 2rem;
    transition: all 0.3s ease;
    box-shadow: -5px 0 15px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
  }

  .nav-right.active {
    right: 0;
  }

  .nav-links {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 0;
  }

  .nav-link {
    width: 100%;
    padding: 1rem;
    border-radius: 8px;
    transition: all 0.3s ease;
  }

  .nav-link:hover {
    background: rgba(102, 189, 64, 0.1);
    transform: translateX(5px);
  }

  .nav-buttons {
    flex-direction: column;
    width: 100%;
    gap: 1rem;
  }

  .nav-button {
    width: 100%;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* 下拉菜单样式优化 */
  .dropdown {
    width: 100%;
  }

  .dropdown-toggle {
    width: 100%;
    padding: 1rem;
    justify-content: space-between;
    border-radius: 8px;
  }

  .dropdown-toggle:hover {
    background: rgba(102, 189, 64, 0.1);
  }

  .dropdown-menu {
    position: static;
    width: 100%;
    opacity: 1;
    visibility: visible;
    box-shadow: none;
    background: transparent;
    padding: 0;
    margin: 0;
    margin-left: 1rem;
  }

  .dropdown-menu::before {
    display: none;
  }

  .dropdown-item {
    padding: 1rem;
    margin: 0.2rem 0;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(102, 189, 64, 0.1);
  }

  .dropdown-item:hover {
    background: rgba(102, 189, 64, 0.1);
    transform: translateX(5px);
  }

  /* ��言切换按钮样式优�� */
  .language-dropdown {
    width: 100%;
    margin: 0;
  }

  .language-btn {
    width: 100%;
    height: auto;
    border-radius: 8px;
    padding: 1rem;
    display: flex;
    justify-content: center;
  }

  .language-menu {
    width: 100%;
    margin-top: 0.5rem;
  }

  .language-option {
    padding: 1rem;
    text-align: center;
  }

  /* 添加遮罩层 */
  .mobile-menu-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
    z-index: 999;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .mobile-menu-overlay.active {
    display: block;
    opacity: 1;
  }
}

/* Footer移动端优化 */
@media (max-width: 768px) {
  .footer-container {
    padding: 0 1.5rem;
  }

  .footer-intro {
    text-align: center;
  }

  .footer-brand {
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
  }

  .footer-logo {
    height: 50px;
  }

  .footer-offices {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .footer-office {
    text-align: center;
    padding: 1.2rem;
  }

  .footer-social {
    justify-content: center;
    flex-wrap: wrap;
  }

  .social-link {
    flex: 0 0 45px;
  }

  .footer-bottom {
    margin-top: 3rem;
    padding: 1.5rem 0;
  }
}

/* 添加遮罩层 */
.mobile-menu-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  opacity: 0;
  transition: opacity 0.3s ease;
}

@media (max-width: 768px) {
  .mobile-menu-overlay.active {
    display: block;
    opacity: 1;
  }
}

/* 用户菜单样式优化 */
.user-menu {
  position: relative;
  display: inline-block;
}

.user-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  padding: 0.6rem 1rem;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.user-button:hover {
  background: rgba(102, 189, 64, 0.1);
}

.dropdown-icon {
  font-size: 0.8rem;
  transition: transform 0.3s ease;
}

.user-menu:hover .dropdown-icon {
  transform: rotate(180deg);
}

.user-dropdown {
  position: absolute;
  top: calc(100% + 5px);
  right: 0;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  min-width: 180px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: all 0.3s ease;
  z-index: 1000;
  overflow: hidden;
}

.user-menu:hover .user-dropdown {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.profile-button,
.logout-button {
  width: 100%;
  padding: 0.8rem 1.2rem;
  border: none;
  background: none;
  font-size: 0.95rem;
  text-align: left;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  transition: all 0.3s ease;
}

.profile-button::before {
  content: '👤';
}

.profile-button {
  color: var(--text-dark);
}

.profile-button:hover {
  background: rgba(102, 189, 64, 0.1);
}

.logout-button {
  color: #ff4d4f;
  border-top: 1px solid #f0f0f0;
}

.logout-button::before {
  content: '🚪';
}

.logout-button:hover {
  background: #fff1f0;
}

/* 移动端适配 */
@media (max-width: 768px) {
  .user-dropdown {
    position: static;
    box-shadow: none;
    opacity: 1;
    visibility: visible;
    transform: none;
    margin-top: 0.5rem;
    background: transparent;
  }

  .profile-button,
  .logout-button {
    padding: 1rem;
    border-radius: 8px;
    margin: 0.2rem 0;
  }

  .profile-button {
    background: rgba(102, 189, 64, 0.1);
  }

  .logout-button {
    background: #fff1f0;
    border: none;
    margin-top: 0.5rem;
  }
}

/* 移动端样式优化 */
@media (max-width: 768px) {
  .logo-image {
    height: 40px;
    margin: -10px 0;
  }

  .logo-text {
    font-size: 1.5rem;
  }

  .nav-container {
    padding: 0 1rem;
    height: 60px;
  }
}