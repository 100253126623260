.faq-page {
  display: flex;
  height: 100vh;
  background: #ffffff;
  padding-top: 90px;
}

/* 左侧边栏样式 */
.faq-sidebar {
  width: 260px;
  background: #f7f7f8;
  border-right: 1px solid #e5e5e5;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 90px);
}

.sidebar-header {
  padding: 1rem;
  border-bottom: 1px solid #e5e5e5;
}

.sidebar-header h2 {
  font-size: 1rem;
  color: #202123;
  margin: 0;
}

.category-list {
  padding: 0.5rem;
}

.category-item {
  width: 100%;
  padding: 0.75rem 1rem;
  background: none;
  border: none;
  border-radius: 6px;
  text-align: left;
  font-size: 0.9rem;
  color: #343541;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-bottom: 0.25rem;
}

.category-item:hover {
  background: #e5e5e5;
}

.category-item.active {
  background: #e5e5e5;
  font-weight: 600;
}

/* 主聊天区域样式 */
.chat-main {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 90px);
}

.chat-header {
  padding: 1rem;
  border-bottom: 1px solid #e5e5e5;
}

.search-box {
  max-width: 600px;
  margin: 0 auto;
  position: relative;
}

.search-box input {
  width: 100%;
  padding: 0.75rem 2.5rem 0.75rem 1rem;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  font-size: 0.9rem;
  background: #ffffff;
}

.search-icon {
  position: absolute;
  right: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  color: #6b6c7b;
}

/* 消息区域样式 */
.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 1rem 0;
}

.message-group {
  padding: 1rem;
  transition: background-color 0.2s ease;
}

.message-group:hover {
  background: #f7f7f8;
}

.message {
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  gap: 1rem;
  align-items: flex-start;
}

.message.user {
  justify-content: flex-end;
}

.message.user .message-content {
  background: var(--primary-green);
  color: white;
  border-radius: 15px 15px 0 15px;
  padding: 1rem;
  max-width: 80%;
}

.message.user .message-text {
  color: white;
}

.message.ai {
  justify-content: flex-start;
}

.message.ai .message-content {
  background: #f0f2f5;
  border-radius: 15px 15px 15px 0;
  padding: 1rem;
  max-width: 80%;
}

.message.ai .message-text {
  color: #343541;
}

.message-avatar {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 8px;
}

.message-timestamp {
  font-size: 0.75rem;
  color: #a0a0a0;
  margin-top: 0.25rem;
}

.message.user .message-timestamp {
  text-align: right;
  color: rgba(255, 255, 255, 0.7);
}

.typing-indicator {
  display: flex;
  gap: 0.3rem;
  padding: 0.5rem;
}

.typing-indicator span {
  width: 8px;
  height: 8px;
  background: #a0a0a0;
  border-radius: 50%;
  animation: typing 1s infinite ease-in-out;
}

.typing-indicator span:nth-child(1) { animation-delay: 0.1s; }
.typing-indicator span:nth-child(2) { animation-delay: 0.2s; }
.typing-indicator span:nth-child(3) { animation-delay: 0.3s; }

@keyframes typing {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-5px); }
}

.quick-questions {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1rem;
}

.quick-question-btn {
  padding: 0.5rem 1rem;
  background: #f0f0f0;
  border: 1px solid #e5e5e5;
  border-radius: 20px;
  font-size: 0.9rem;
  color: #343541;
  cursor: pointer;
  transition: all 0.2s ease;
}

.quick-question-btn:hover {
  background: #e5e5e5;
}

/* 底部输入区域样式 */
.chat-footer {
  padding: 1rem;
  border-top: 1px solid #e5e5e5;
  background: #ffffff;
}

.chat-input {
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  gap: 0.5rem;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  padding: 0.5rem;
}

.chat-input input {
  flex: 1;
  padding: 0.75rem;
  border: none;
  font-size: 0.9rem;
  background: none;
}

.chat-input input:focus {
  outline: none;
}

.send-button {
  padding: 0.5rem 1rem;
  background: var(--primary-green);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.send-button:hover {
  background: var(--primary-blue);
}

.input-footer {
  max-width: 800px;
  margin: 0.5rem auto 0;
  text-align: center;
  font-size: 0.8rem;
  color: #6b6c7b;
}

/* 响应式调整 */
@media (max-width: 768px) {
  .faq-page {
    flex-direction: column;
    height: auto;
    min-height: 100vh;
  }

  .faq-sidebar {
    width: 100%;
    height: auto;
    max-height: 300px;
    overflow-y: auto;
  }

  .chat-main {
    height: calc(100vh - 390px);
  }

  .message {
    padding: 0.5rem;
  }
}

/* 建议问题样式 */
.suggested-questions {
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.suggested-questions button {
  padding: 0.5rem 1rem;
  background: rgba(102,189,64,0.1);
  border: 1px solid rgba(102,189,64,0.2);
  border-radius: 20px;
  font-size: 0.9rem;
  color: var(--primary-green);
  cursor: pointer;
  transition: all 0.2s ease;
}

.suggested-questions button:hover {
  background: rgba(102,189,64,0.2);
}

/* 反馈按钮样式 */
.message-feedback {
  margin-top: 0.5rem;
  display: flex;
  gap: 0.5rem;
}

.message-feedback button {
  padding: 0.25rem 0.5rem;
  background: none;
  border: none;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.2s ease;
}

.message-feedback button:hover {
  opacity: 1;
}

/* 添加移动端分类收纳按钮 */
.category-toggle {
    display: none;
    width: 100%;
    padding: 1rem;
    background: white;
    border: none;
    border-bottom: 1px solid #e5e5e5;
    font-size: 1rem;
    font-weight: 600;
    color: var(--text-dark);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.category-toggle-icon {
    transition: transform 0.3s ease;
}

.category-toggle-icon.active {
    transform: rotate(180deg);
}

/* 移动端响应式调整 */
@media (max-width: 768px) {
    .faq-page {
        flex-direction: column;
        padding-top: 70px;
    }

    .category-toggle {
        display: flex;
    }

    .faq-sidebar {
        width: 100%;
        height: auto;
        position: relative;
        border-right: none;
        overflow: hidden;
        transition: max-height 0.3s ease;
    }

    .faq-sidebar.collapsed {
        max-height: 60px;  /* 只显示切换按钮的高度 */
    }

    .faq-sidebar.expanded {
        max-height: 300px;  /* 展开时的最大高度 */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    .sidebar-header {
        display: none;  /* 隐藏原标题 */
    }

    .category-list {
        background: white;
        padding: 0;
        margin-top: 0;
    }

    .category-item {
        padding: 1rem;
        border-bottom: 1px solid #f0f0f0;
        margin: 0;
        border-radius: 0;
    }

    .category-item:last-child {
        border-bottom: none;
    }

    .chat-main {
        height: calc(100vh - 130px);
    }

    /* 调整聊天区域的样式 */
    .message-content {
        max-width: 90%;
    }

    .quick-questions {
        flex-wrap: nowrap;
        overflow-x: auto;
        padding-bottom: 0.5rem;
        -webkit-overflow-scrolling: touch;
        scrollbar-width: none;  /* Firefox */
    }

    .quick-questions::-webkit-scrollbar {
        display: none;  /* Chrome, Safari */
    }

    .quick-question-btn {
        flex: 0 0 auto;
        white-space: nowrap;
    }
} 