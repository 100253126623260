/* Hero Section */
.home {
  padding-top: 90px;
}

.hero-section {
  position: relative;
  min-height: 100vh;
  padding: 6rem 0 4rem;
  overflow: hidden;
}

.hero-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 5%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 4rem;
  position: relative;
  z-index: 2;
}

.hero-content {
  position: relative;
}

.hero-badges {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
  flex-wrap: wrap;
}

.hero-badge {
  display: inline-flex;
  align-items: center;
  gap: 0.8rem;
  padding: 0.8rem 1.5rem;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 50px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(102, 189, 64, 0.1);
}

.certification-badge {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.6rem 1.2rem;
  background: rgba(56, 119, 186, 0.1);
  border-radius: 50px;
  border: 1px solid rgba(56, 119, 186, 0.2);
  color: var(--primary-blue);
  font-weight: 500;
  font-size: 0.9rem;
}

.hero-title {
  font-size: 2.8rem;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  font-weight: 800;
}

.title-highlight {
  position: relative;
  color: var(--primary-green);
}

.title-highlight::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0.2rem;
  width: 100%;
  height: 0.5rem;
  background: var(--primary-green);
  opacity: 0.2;
  border-radius: 4px;
}

.hero-subtitle {
  font-size: 1.1rem;
  color: var(--text-light);
  margin-bottom: 2.5rem;
  line-height: 1.8;
  max-width: 80%;
}

.hero-buttons {
  display: flex;
  gap: 1rem;
}

.hero-visual {
  position: relative;
  width: 100%;
  height: 100%;
}

.image-frame {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  border-radius: 30px;
  overflow: hidden;
}

.image-background {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 120%;
  height: 120%;
  background: linear-gradient(
    135deg,
    rgba(102, 189, 64, 0.1),
    rgba(56, 119, 186, 0.1)
  );
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  animation: morphBackground 15s ease-in-out infinite;
}

.hero-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.image-decoration {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 140%;
  height: 140%;
  background: radial-gradient(
    circle at center,
    rgba(102, 189, 64, 0.1) 0%,
    rgba(56, 119, 186, 0.1) 50%,
    transparent 70%
  );
  filter: blur(40px);
  z-index: 0;
}

.hero-pattern {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.pattern-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('../images/pattern.png');
  background-size: 200px;
  opacity: 0.03;
  animation: patternFloat 30s linear infinite;
}

.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    135deg,
    rgba(102, 189, 64, 0.03),
    rgba(56, 119, 186, 0.03)
  );
  backdrop-filter: blur(10px);
}

@keyframes morphBackground {
  0% {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  }
  50% {
    border-radius: 70% 30% 30% 70% / 70% 70% 30% 30%;
  }
  100% {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  }
}

@keyframes patternFloat {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 200px 200px;
  }
}

/* 响应式调整 */
@media (max-width: 1200px) {
  .hero-container {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .hero-badges {
    justify-content: center;
  }

  .hero-subtitle {
    margin: 0 auto 2.5rem;
  }

  .hero-buttons {
    justify-content: center;
  }

  .hero-visual {
    max-width: 600px;
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  .hero-title {
    font-size: 2.2rem;
  }

  .hero-subtitle {
    font-size: 1rem;
  }

  .hero-visual {
    max-width: 400px;
  }

  .hero-buttons {
    flex-direction: column;
  }

  .hero-buttons button {
    width: 100%;
  }
}

/* Stats Section */
.stats-section {
  padding: 4rem 0;
  background: linear-gradient(135deg, var(--primary-green), var(--primary-blue));
  position: relative;
  overflow: hidden;
}

.stats-container {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.stats-title {
  color: white;
  font-size: 2.2rem;
  margin-bottom: 3rem;
  line-height: 1.4;
  font-weight: 700;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  position: relative;
  z-index: 1;
}

.stat-card {
  background: rgba(255, 255, 255, 0.1);
  padding: 2rem;
  border-radius: 20px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: transform 0.3s ease;
}

.stat-card:hover {
  transform: translateY(-10px);
}

.stat-number {
  display: block;
  font-size: 2.8rem;
  font-weight: 800;
  margin-bottom: 1rem;
  background: linear-gradient(135deg, #fff, rgba(255,255,255,0.8));
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
}

.stat-label {
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.1rem;
}

/* 响应式调整 */
@media (max-width: 768px) {
  .stats-title {
    font-size: 1.8rem;
  }

  .stats-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .stat-card {
    padding: 1.5rem;
  }

  .stat-number {
    font-size: 1.8rem;
  }
}

/* Features Section新样式 */
.features-section {
  padding: 4rem 0;
  background: white;
  position: relative;
}

.features-container {
  max-width: 1400px;
  margin: 0 auto;
}

.features-header {
  text-align: center;
  margin-bottom: 5rem;
}

.features-header h2 {
  font-size: 2.2rem;
  color: var(--text-dark);
  margin-bottom: 1rem;
}

.features-header p {
  font-size: 1rem;
  color: var(--text-light);
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2.5rem;
}

.feature-card {
  background: white;
  padding: 1.8rem;
  border-radius: 20px;
  position: relative;
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.08);
}

.feature-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.08);
  border-color: transparent;
}

.feature-number {
  position: absolute;
  top: 2rem;
  right: 2rem;
  font-size: 1.2rem;
  font-weight: 700;
  color: var(--primary-blue);
  opacity: 0.3;
}

.feature-icon-wrapper {
  width: 50px;
  height: 50px;
  background: linear-gradient(135deg, 
    rgba(102,189,64,0.1),
    rgba(56,119,186,0.1)
  );
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  transition: all 0.3s ease;
}

.feature-card:hover .feature-icon-wrapper {
  transform: scale(1.1);
  background: linear-gradient(135deg, 
    rgba(102,189,64,0.2),
    rgba(56,119,186,0.2)
  );
}

.feature-icon {
  font-size: 2rem;
}

.feature-content h3 {
  font-size: 1.3rem;
  color: var(--text-dark);
  margin-bottom: 1rem;
}

.feature-content p {
  color: var(--text-light);
  line-height: 1.6;
}

/* 响应式调整 */
@media (max-width: 768px) {
  .features-section {
    padding: 6rem 5%;
  }

  .features-header h2 {
    font-size: 1.8rem;
  }

  .features-grid {
    gap: 2rem;
  }

  .feature-card {
    padding: 2rem;
  }
}

/* About Section样式优化 */
.about-section {
  position: relative;
  padding: 6rem 5%;
  background: var(--primary-blue);
  overflow: hidden;
  color: white;
}

.about-bg-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.1;
  mix-blend-mode: overlay;
}

.about-container {
  position: relative;
  z-index: 2;
  max-width: 1200px;
  margin: 0 auto;
}

.about-header {
  text-align: center;
  margin-bottom: 4rem;
}

.about-badge-highlight {
  display: inline-block;
  background: rgba(255, 255, 255, 0.15);
  padding: 1rem 2.5rem;
  border-radius: 50px;
  font-size: 1.2rem;
  font-weight: 600;
  color: white;
  margin-bottom: 2rem;
  border: 2px solid rgba(255, 255, 255, 0.3);
}

.about-title {
  font-size: 2.5rem;
  color: white;
  margin-bottom: 1.5rem;
  font-weight: 700;
}

.about-description {
  font-size: 1.1rem;
  color: rgba(255, 255, 255, 0.9);
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.8;
}

.about-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

.feature-card {
  background: rgba(255, 255, 255, 0.1);
  padding: 2rem;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: all 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-10px);
  background: rgba(255, 255, 255, 0.15);
}

.feature-icon {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: white;
}

.feature-title {
  font-size: 1.3rem;
  color: white;
  margin-bottom: 1rem;
  font-weight: 600;
}

.feature-text {
  color: rgba(255, 255, 255, 0.9);
  line-height: 1.6;
  font-size: 1rem;
}

/* 响应式调整 */
@media (max-width: 768px) {
  .about-section {
    padding: 4rem 1.5rem;
  }

  .about-title {
    font-size: 2rem;
  }

  .about-cards {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
}

/* Values Section样式优化 */
.values-section-green {
  padding: 6rem 0;
  background: var(--primary-green);
  position: relative;
  overflow: hidden;
}

.values-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
  position: relative;
  z-index: 2;
}

.values-header {
  text-align: center;
  margin-bottom: 4rem;
}

.section-badge {
  display: inline-block;
  padding: 0.8rem 1.5rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50px;
  color: white;
  font-weight: 600;
  margin-bottom: 1.5rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.values-header h2 {
  font-size: 2.5rem;
  color: white;
  margin-bottom: 1.5rem;
}

.values-main {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-bottom: 4rem;
}

.main-text {
  background: rgba(255, 255, 255, 0.1);
  padding: 2rem;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: all 0.3s ease;
}

.main-text:hover {
  transform: translateY(-10px);
  background: rgba(255, 255, 255, 0.15);
}

.main-text h3 {
  font-size: 1.5rem;
  color: white;
  margin-bottom: 1rem;
}

.main-text p {
  color: rgba(255, 255, 255, 0.9);
  line-height: 1.8;
}

.values-features {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  margin-top: 4rem;
}

.features-image {
  position: relative;
  height: 300px;
  border-radius: 20px;
  overflow: hidden;
}

.features-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.features-list {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.feature-item {
  background: rgba(255, 255, 255, 0.1);
  padding: 2rem;
  border-radius: 15px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: all 0.3s ease;
}

.feature-item:hover {
  transform: translateX(10px);
  background: rgba(255, 255, 255, 0.15);
}

.feature-content h4 {
  font-size: 1.3rem;
  color: white;
  margin-bottom: 1rem;
  font-weight: 600;
}

.feature-content p {
  color: rgba(255, 255, 255, 0.9);
  line-height: 1.8;
}

/* 响应式调整 */
@media (max-width: 1024px) {
  .values-main {
    grid-template-columns: 1fr;
  }

  .values-features {
    grid-template-columns: 1fr;
  }

  .features-image {
    height: 250px;
  }
}

@media (max-width: 768px) {
  .values-header h2 {
    font-size: 2rem;
  }

  .main-text {
    padding: 1.5rem;
  }

  .feature-item {
    padding: 1.5rem;
  }

  .features-image {
    height: 200px;
  }
}

/* Testimonials Section样式优化 */
.testimonials-section {
  padding: 6rem 0;
  background: #f8f9fa;
}

.section-header {
  text-align: center;
  margin-bottom: 4rem;
}

.section-header h2 {
  font-size: 2.5rem;
  color: var(--text-dark);
  margin-bottom: 1rem;
  font-weight: 700;
  background: linear-gradient(135deg, #1a365d, #2d4a8a);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
}

.section-header p {
  font-size: 1.2rem;
  color: var(--text-light);
  max-width: 600px;
  margin: 0 auto;
}

.testimonials-slider {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.testimonial-card {
  background: white;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: var(--shadow-md);
  transition: var(--transition);
}

.testimonial-image {
  width: 100%;
  height: 200px; /* 固定高度 */
  overflow: hidden;
}

.testimonial-image img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* 确保图片覆盖整个容器但不变形 */
  transition: transform 0.3s ease;
}

.testimonial-card:hover {
  transform: translateY(-10px);
  box-shadow: var(--shadow-lg);
}

.testimonial-card:hover .testimonial-image img {
  transform: scale(1.05);
}

.testimonial-card p {
  padding: 1.5rem;
  color: var(--text-light);
  font-size: 1rem;
  line-height: 1.8;
}

.testimonial-author {
  padding: 0 1.5rem;
  color: var(--text-dark);
  font-weight: 600;
  font-size: 1.1rem;
}

.testimonial-location {
  padding: 0.5rem 1.5rem 1.5rem;
  color: var(--text-light);
  font-size: 0.9rem;
}

/* 响应式调整 */
@media (max-width: 768px) {
  .section-header h2 {
    font-size: 2rem;
  }

  .section-header p {
    font-size: 1rem;
  }

  .testimonial-image {
    height: 180px; /* 移动端稍微降低高度 */
  }
} 