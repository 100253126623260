.profile-page {
    padding: 2rem;
    padding-top: 90px;
    min-height: 100vh;
    background: #f5f5f5;
}

.profile-container {
    max-width: 800px;
    margin: 0 auto;
}

.profile-header {
    margin-bottom: 2rem;
    text-align: center;
}

.profile-header h1 {
    font-size: 2rem;
    color: var(--text-dark);
}

.profile-card {
    background: white;
    border-radius: 20px;
    padding: 2rem;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
    display: flex;
    gap: 2rem;
}

.profile-avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: var(--gradient-primary);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.5rem;
    font-weight: bold;
}

.profile-info {
    flex: 1;
}

.info-item {
    margin-bottom: 1.5rem;
}

.info-item label {
    display: block;
    font-size: 0.9rem;
    color: var(--text-light);
    margin-bottom: 0.5rem;
}

.info-item span {
    font-size: 1.1rem;
    color: var(--text-dark);
    font-weight: 500;
}

/* 下线树状图样式 */
.downline-tree-section {
    margin-top: 2rem;
    background: white;
    border-radius: 20px;
    padding: 2rem;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
}

.downline-tree-section h2 {
    margin-bottom: 2rem;
    color: var(--text-dark);
    font-size: 1.5rem;
}

.tree-container {
    overflow: hidden;
    padding: 1rem;
    min-height: 300px;
    position: relative;
    cursor: grab;
    background: #f9f9f9;
    border-radius: 8px;
}

.tree-container:active {
    cursor: grabbing;
}

.tree-content {
    transition: transform 0.1s ease;
    min-width: fit-content;
    padding: 1rem;
    transform-origin: center center;
}

/* 自定义组织树连接线样式 */
.react-organizational-chart {
    margin: 0;
    padding: 0;
    width: 100%;
}

.react-organizational-chart .org-tree-node {
    margin: 0;
    padding: 0;
}

.react-organizational-chart .org-tree-node-children {
    margin-top: 1rem;
    padding-top: 1rem;
}

/* 添加动画效果 */
.react-organizational-chart .org-tree-node {
    animation: nodeAppear 0.5s ease forwards;
    opacity: 0;
    transform: translateY(20px);
}

@keyframes nodeAppear {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.loading {
    text-align: center;
    padding: 2rem;
    color: var(--text-light);
}

.error-message {
    text-align: center;
    padding: 2rem;
    color: #ff4d4f;
    background: #fff2f0;
    border-radius: 8px;
}

/* 响应式调整 */
@media (max-width: 768px) {
    .tree-container {
        padding: 1rem;
    }

    .tree {
        min-width: 600px;
    }

    .tree-children {
        gap: 2rem;
    }

    .node-content {
        min-width: 160px;
        padding: 1rem;
    }
}

.node-content[data-role="admin"] {
    border-color: #1a365d;
    background: rgba(26, 54, 93, 0.05);
}

.node-content[data-role="boss"] {
    border-color: #d4b106;
    background: rgba(212, 177, 6, 0.05);
}

.node-content[data-role="manager"] {
    border-color: #237804;
    background: rgba(35, 120, 4, 0.05);
}

.node-content[data-role="agent"] {
    border-color: #096dd9;
    background: rgba(9, 109, 217, 0.05);
}

.node-avatar[data-role="admin"] {
    background: linear-gradient(135deg, #1a365d, #2d4a8a);
}

.node-avatar[data-role="boss"] {
    background: linear-gradient(135deg, #d4b106, #fadb14);
}

.node-avatar[data-role="manager"] {
    background: linear-gradient(135deg, #237804, #52c41a);
}

.node-avatar[data-role="agent"] {
    background: linear-gradient(135deg, #096dd9, #1890ff);
}

.role-badge[data-role="admin"] {
    background: linear-gradient(135deg, #1a365d, #2d4a8a);
    color: white;
}

.role-badge[data-role="boss"] {
    background: linear-gradient(135deg, #d4b106, #fadb14);
    color: black;
}

.role-badge[data-role="manager"] {
    background: linear-gradient(135deg, #237804, #52c41a);
    color: white;
}

.role-badge[data-role="agent"] {
    background: linear-gradient(135deg, #096dd9, #1890ff);
    color: white;
}

/* 每个层级的缩进 */
.tree-node[style*="--level"] {
    margin-left: calc(var(--level) * 2rem);
}

/* 连接线动画 */
.tree-node::before,
.tree-node::after,
.tree-children::before {
    animation: lineGrow 0.5s ease forwards;
    transform-origin: top;
}

@keyframes lineGrow {
    from {
        transform: scaleY(0);
    }
    to {
        transform: scaleY(1);
    }
}

/* 水平线动画 */
.tree-children::before {
    animation: lineGrowHorizontal 0.5s ease forwards;
    transform-origin: left;
}

@keyframes lineGrowHorizontal {
    from {
        transform: scaleX(0);
    }
    to {
        transform: scaleX(1);
    }
}

/* 多层级下线的缩进和连接线 */
.tree-children .tree-children {
    margin-left: 2rem;
}

.tree-children .tree-children::before {
    left: 0;
    width: calc(100% - 2rem);
}

.expand-indicator {
    margin-left: 8px;
    font-size: 12px;
    opacity: 0.6;
    transition: transform 0.3s ease;
}

.expanded .expand-indicator {
    transform: rotate(180deg);
}

/* 添加悬停效果 */
.node-content {
    cursor: pointer;
    transition: all 0.3s ease;
}

.node-content:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.12);
}

/* 展开/收起动画 */
.tree-node {
    transition: all 0.3s ease;
}

.tree-children {
    transition: all 0.3s ease;
    overflow: hidden;
}

/* 添加树控制按钮样式 */
.tree-controls {
    margin-bottom: 1rem;
    display: flex;
    gap: 0.5rem;
    justify-content: center;
}

.tree-controls button {
    width: 36px;
    height: 36px;
    padding: 0;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: white;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tree-controls button:not(:last-child) {
    margin-right: 8px;
}

.zoom-icon {
    font-size: 18px;
    font-weight: normal;
    line-height: 1;
}

.tree-controls button:hover {
    background: #f5f5f5;
}

/* 模态框样式 */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    width: 90%;
    max-width: 400px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.modal-content h3 {
    margin-bottom: 1.5rem;
    color: var(--text-dark);
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: var(--text-dark);
}

.form-group input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
}

.modal-buttons {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    margin-top: 2rem;
}

.submit-button {
    padding: 0.75rem 1.5rem;
    background: #1890ff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s ease;
    font-weight: 500;
}

.submit-button:hover {
    background: #096dd9;
}

.cancel-button {
    padding: 0.75rem 1.5rem;
    background: white;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
    color: rgba(0, 0, 0, 0.65);
    font-weight: 500;
}

.cancel-button:hover {
    color: #1890ff;
    border-color: #1890ff;
    background: white;
}

.change-password-button {
    padding: 0.75rem 1.5rem;
    background: white;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.change-password-button:hover {
    background: var(--primary-color);
    color: white;
}

.profile-actions {
    margin-top: 1rem;
}

/* 全屏模式样式 */
.tree-container.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    background: #f9f9f9;
    padding: 2rem;
    margin: 0;
    border-radius: 0;
    overflow: hidden;
}

/* 全屏模式下的控制按钮样式 */
.fullscreen-controls {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1002;
    background: rgba(255, 255, 255, 0.9);
    padding: 0.8rem;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    gap: 0.5rem;
    backdrop-filter: blur(5px);
}

.fullscreen-controls button {
    width: 36px;
    height: 36px;
    padding: 0;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: white;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fullscreen-controls button.exit-fullscreen {
    min-width: unset;
    padding: 0;
    background: white;
    color: inherit;
    border: 1px solid #ddd;
}

.fullscreen-controls button.exit-fullscreen:hover {
    background: #f5f5f5;
}

/* 移动端适配 */
@media (max-width: 768px) {
    .fullscreen-controls {
        padding: 0.5rem;
        width: 90%;
        max-width: 400px;
    }

    .fullscreen-controls button {
        min-width: 36px;
        height: 36px;
        font-size: 0.9rem;
    }
}

/* 调整全屏模式下的内容容器 */
.tree-container.fullscreen .tree-content {
    height: calc(100vh - 4rem);
    width: 100%;
    overflow: visible;
}

/* 全屏模式下的缩放提示 */
.tree-container.fullscreen::after {
    bottom: 2rem;
    right: 2rem;
}

/* 优化树容器样式 */
.tree-container {
    min-height: 500px;
    transition: all 0.3s ease;
}

/* 调整节点间距 */
.react-organizational-chart .org-tree-node-children {
    margin-top: 0.5rem;
    padding-top: 0.5rem;
}

/* 优化节点样式 */
.StyledNode {
    padding: 0.75rem;
    min-width: 180px;
}

/* 添加小地图导航 */
.tree-minimap {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    width: 200px;
    height: 150px;
    background: rgba(255, 255, 255, 0.9);
    border: 1px solid #ddd;
    border-radius: 4px;
    overflow: hidden;
    display: none;
}

.tree-container.fullscreen .tree-minimap {
    display: block;
}

/* 移动端适配样式 */
@media (max-width: 768px) {
    .profile-page {
        padding: 1rem;
        padding-top: 70px;
    }

    .profile-card {
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 1rem;
        padding: 1.5rem;
    }

    .profile-avatar {
        width: 80px;
        height: 80px;
        font-size: 2rem;
    }

    .profile-info {
        width: 100%;
    }

    .downline-tree-section {
        padding: 1rem;
    }

    /* 调整树控制按钮 */
    .tree-controls {
        flex-wrap: wrap;
        gap: 0.5rem;
    }

    .tree-controls button {
        flex: 1;
        min-width: 40px;
        height: 40px;
        margin: 0;
    }

    /* 调整树节点样式 */
    .StyledNode {
        padding: 0.5rem;
        min-width: 150px;
        font-size: 0.9rem;
    }

    .NodeAvatar {
        width: 32px;
        height: 32px;
        font-size: 0.9rem;
    }

    .RoleBadge {
        padding: 0.3rem 0.6rem;
        font-size: 0.8rem;
    }

    /* 调整全屏模式 */
    .tree-container.fullscreen {
        padding: 1rem;
    }

    .tree-container.fullscreen .tree-controls {
        top: 10px;
        right: 10px;
        padding: 0.3rem;
    }

    /* 调整密码修改模态框 */
    .modal-content {
        width: 95%;
        padding: 1.5rem;
    }

    .modal-buttons {
        flex-direction: column;
        gap: 0.5rem;
    }

    .modal-buttons button {
        width: 100%;
    }

    /* 优化树的缩放提示 */
    .tree-container::after {
        font-size: 0.7rem;
        padding: 0.3rem;
        bottom: 0.5rem;
        right: 0.5rem;
    }

    /* 优化树的连接线 */
    .react-organizational-chart .org-tree-node-children {
        margin-top: 0.5rem;
        padding-top: 0.5rem;
    }

    /* 调整拖动区域 */
    .tree-container {
        min-height: 250px;
        touch-action: none;
    }

    /* 添加触摸设备支持 */
    .tree-content {
        touch-action: none;
    }
}

/* 小屏幕设备的额外优化 */
@media (max-width: 480px) {
    .profile-page {
        padding: 0.5rem;
        padding-top: 60px;
    }

    .profile-header h1 {
        font-size: 1.5rem;
    }

    .StyledNode {
        min-width: 120px;
        font-size: 0.8rem;
    }

    .tree-controls button {
        width: 36px;
        height: 36px;
    }

    /* 调整按钮文字大小 */
    .change-password-button {
        font-size: 0.9rem;
        padding: 0.5rem 1rem;
    }

    /* 优化全屏模式下的控制按钮 */
    .tree-container.fullscreen .tree-controls button {
        width: 32px;
        height: 32px;
        font-size: 0.8rem;
    }
}

/* 添加触摸设备的交互支持 */
@media (hover: none) {
    .tree-container {
        cursor: default;
    }

    .node-content:hover {
        transform: none;
    }

    .tree-controls button:active {
        background: #f0f0f0;
    }
}

/* 移除滚轮提示 */
.tree-container::after {
    display: none;
}