.user-management-page {
    padding: 2rem;
    padding-top: 90px;
    min-height: 100vh;
    background: #f5f5f5;
}

.user-management-container {
    max-width: 1200px;
    margin: 0 auto;
    background: white;
    border-radius: 20px;
    padding: 2rem;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
}

.user-management-container h1 {
    margin-bottom: 2rem;
    color: var(--text-dark);
}

.users-table {
    overflow-x: auto;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    padding: 1rem;
    text-align: left;
    border-bottom: 1px solid #eee;
}

th {
    background: #f9f9f9;
    font-weight: 600;
    color: var(--text-dark);
}

.role-badge {
    display: inline-block;
    padding: 0.3rem 0.8rem;
    border-radius: 20px;
    font-size: 0.9rem;
}

.role-badge.admin {
    background: linear-gradient(135deg, #1a365d, #2d4a8a);
    color: white;
}

.role-badge.boss {
    background: linear-gradient(135deg, #d4b106, #fadb14);
    color: black;
}

.role-badge.manager {
    background: linear-gradient(135deg, #237804, #52c41a);
    color: white;
}

.role-badge.agent {
    background: linear-gradient(135deg, #096dd9, #1890ff);
    color: white;
}

.action-buttons {
    display: flex;
    gap: 0.5rem;
}

.edit-button,
.save-button,
.cancel-button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.edit-button {
    background: var(--primary-blue);
    color: white;
}

.save-button {
    background: var(--primary-green);
    color: white;
}

.cancel-button {
    background: #ff4d4f;
    color: white;
}

select {
    padding: 0.5rem;
    border-radius: 6px;
    border: 1px solid #d9d9d9;
}

.loading {
    text-align: center;
    padding: 2rem;
    color: var(--text-light);
}

.error-message {
    background: #fff2f0;
    border: 1px solid #ffccc7;
    color: #ff4d4f;
    padding: 1rem;
    border-radius: 8px;
    margin-bottom: 1rem;
}

.edit-input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    margin-bottom: 0.5rem;
}

.edit-select {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
}

.edit-form {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.action-buttons {
    display: flex;
    gap: 0.5rem;
    margin-top: 0.5rem;
}

.header-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}

.add-button {
    padding: 0.8rem 1.5rem;
    background: var(--primary-green);
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.add-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(102, 189, 64, 0.2);
}

.delete-button {
    padding: 0.5rem 1rem;
    background: #ff4d4f;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.delete-button:hover {
    background: #ff7875;
}

.new-user-row {
    background: rgba(102, 189, 64, 0.05);
}

.user-controls {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
    padding: 1rem;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.sort-buttons {
    display: flex;
    gap: 0.5rem;
}

.sort-button {
    padding: 0.5rem 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: white;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 0.9rem;
}

.sort-button:hover {
    border-color: #1890ff;
    color: #1890ff;
}

.sort-button.active {
    background: #1890ff;
    color: white;
    border-color: #1890ff;
}

.upline-search-container {
    position: relative;
    margin-bottom: 1rem;
}

.upline-suggestions {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
}

.upline-suggestion-item {
    padding: 0.8rem;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee;
}

.upline-suggestion-item:last-child {
    border-bottom: none;
}

.upline-suggestion-item:hover {
    background: #f5f5f5;
}

.upline-ic {
    color: #666;
    font-size: 0.9rem;
}

/* 添加滚动条样式 */
.upline-suggestions::-webkit-scrollbar {
    width: 6px;
}

.upline-suggestions::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.upline-suggestions::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
}

.upline-suggestions::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.search-container {
    margin-bottom: 1rem;
    padding: 0 1rem;
}

.search-input {
    width: 100%;
    padding: 0.8rem 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    transition: all 0.3s ease;
    background: white;
}

.search-input:focus {
    outline: none;
    border-color: #1890ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.search-input::placeholder {
    color: #bbb;
}